import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  InputLabel,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import backgroundImage from "../../assets/map.svg";
import WestIcon from "@mui/icons-material/West";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { onboardingStyle as style } from "../../core/style";
import useDebounce from "../../core/debounce";
import { useLocation } from "react-router-dom";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import {
  gridmarketApi as axios,
  gridmarketStagingApi as stagingApi,
} from "../../core/interceptor";
import {
  apiRoute,
  gridmarketKey,
  gridmarketStagingKey,
  httpMethod,
  onboardingComponents as components,
} from "../../core/constants";
import { LoadingButton } from "@mui/lab";
import { formatter } from "../../core/helper";
import agent from "../../core/agent";

function OnboardingComponent() {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [component, setComponent] = useState(components.discoverSavings);
  const [estimates, setEstimates] = useState({
    co2: "",
    revenue: "",
    address: "",
  });
  const [widget, setWidget] = useState(false);
  const [address, setAddress] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [form, setForm] = useState([]);

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname === "/savings" && !widget) {
      setWidget(true);
    }
  }, []);

  if (pathname === "/savings" && !widget) {
    setWidget(true);
  }

  const navigateTo = useCallback((value) => {
    setComponent(value);
  }, []);
  const setEstimatesFromDiscoverSavings = useCallback((value) => {
    setEstimates(value);
  }, []);

  return (
    <Box
      sx={
        widget
          ? {
              ...style.container,
              ...(matchesMobile && style.containerHeightMobile),
              backgroundColor: "transparent",
              height: "80%",
              fontFamily: "Mulish",
            }
          : {
              ...style.container,
              ...(matchesMobile && style.containerHeightMobile),
              backgroundColor: "transparent",
              height: "80%",
            }
      }
    >
      {!widget ? (
        <Box component="img" sx={style.img} src={backgroundImage} />
      ) : (
        <Box
          sx={{
            minHeight: "300px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: "block",
            backgroundColor: "white",
            opacity: 0.1,
          }}
        />
      )}
      {/* <Box> */}
      <Box
        sx={matchesMobile ? style.contentWrapperSmall : style.contentWrapper}
      >
        <Stack
          spacing={3}
          sx={style.contentContainer}
          // sx={{ height: 400 }}
        >
          {component === components.discoverSavings && (
            <DiscoverSavings
              matchesMobile={matchesMobile}
              navigateTo={navigateTo}
              // setEstimatesFromDiscoverSavings={
              //   setEstimatesFromDiscoverSavings
              // }
              widget={widget}
              address={address}
              buildingId={buildingId}
              setBuildingId={setBuildingId}
              setEstimates={setEstimates}
            />
          )}
          {component === components.survey && (
            <SurveyQuestions
              matchesMobile={matchesMobile}
              widget={widget}
              navigateTo={navigateTo}
              form={form}
              setForm={setForm}
              // setEstimatesFromDiscoverSavings={
              //   setEstimatesFromDiscoverSavings
              // }
            />
          )}
          {component === components.getStarted && (
            <GetStarted
              matchesMobile={matchesMobile}
              data={estimates}
              navigateTo={navigateTo}
              widget={widget}
              buildingId={buildingId}
              form={form}
              setForm={setForm}
              estimates={estimates}
            />
          )}
        </Stack>
      </Box>
      {/* </Box> */}
    </Box>
  );
}
export default React.memo(OnboardingComponent);

function SurveyQuestions(props) {
  const { matchesMobile, widget, form, setForm } = props;

  const [step, setStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const handleChange = (e) => {
    if (e) {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  // const handleChangeWithMultipleOptions = (e) => {
  //   console.log("test in handlechange");
  //   if (e) {
  //     setForm({ ...form, [e.target.name]: [e.target.value] });
  //   }
  //   console.log(form, "form in handleChangeWithMultipleOptions");
  // };

  const handleChangeWithMultipleOptions = (e) => {
    const { name, checked, value } = e.target;

    setForm((prevForm) => {
      const updatedValues = {
        ...prevForm,
        [name]: checked
          ? [...(prevForm[name] || []), value]
          : (prevForm[name] || []).filter((item) => item !== value),
      };
      return updatedValues;
    });
  };
  const handleNext = (e) => {
    if (step < 5) {
      setStep(step + 1);
      setActiveStep(activeStep + 1);
    }
    if (step === 5) {
      console.log(form, "form");
      props.navigateTo(components.getStarted);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setActiveStep(activeStep - 1);
    }
  };

  const textColor = () => {
    if (widget) {
      return "black";
    } else {
      return "white";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
        minHeight: 330,
      }}
    >
      <Box
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: 250,
          // width: 400,
          // bgcolor: "background.default",
        }}
      >
        {step === 0 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                id="start"
                name="start"
                variant="h5"
                fontSize="18px"
                // </FormControl> fontWeight={widget ? null : 800}
                sx={
                  widget
                    ? { mb: 2, fontFamily: "Mulish", fontWeight: 800 }
                    : {
                        mb: 2,
                        fontSize: 22,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                Let&apos;s get started!
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="Let's get started!"
              >
                <FormControlLabel
                  name="Let's get started!"
                  value="I want to save money"
                  control={<Checkbox sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      fontWeight={widget ? 400 : 600}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : null
                      }
                    >
                      I want to save money
                    </Typography>
                  }
                  onChange={handleChangeWithMultipleOptions}
                />
                <FormControlLabel
                  name="Let's get started!"
                  value="I need to hit carbon/sustainability targets"
                  control={
                    <Checkbox sx={widget ? null : { color: textColor() }} />
                  }
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I need to hit carbon/sustainability targets
                    </Typography>
                  }
                  onChange={handleChangeWithMultipleOptions}
                />
                <FormControlLabel
                  name="Let's get started!"
                  value="I am preparing for EV"
                  control={<Checkbox sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I am preparing for EV
                    </Typography>
                  }
                  onChange={handleChangeWithMultipleOptions}
                />
                <FormControlLabel
                  name="Let's get started!"
                  value="I am literally just curious"
                  control={<Checkbox sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I am literally just curious
                    </Typography>
                  }
                  onChange={handleChangeWithMultipleOptions}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        {step === 1 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                variant="h5"
                id="start"
                name="start"
                sx={
                  widget
                    ? {
                        mb: 2,
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                      }
                    : {
                        mb: 2,
                        fontSize: 24,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                What is your role?
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="What is your role?"
              >
                <FormControlLabel
                  name="What is your role?"
                  value="The decision is mine"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      The decision is mine
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What is your role?"
                  value="I pitch to my board"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I pitch to my board
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What is your role?"
                  value="I report to a manager"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I report to a manager
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What is your role?"
                  value="I have no idea"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I have no idea
                    </Typography>
                  }
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        {step === 2 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                variant="h5"
                id="start"
                name="start"
                sx={
                  widget
                    ? {
                        mb: 2,
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                      }
                    : {
                        mb: 2,
                        fontSize: 24,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                How about a timeline?
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="How about a timeline?"
              >
                <FormControlLabel
                  name="How about a timeline?"
                  value="I need this project in yesterday"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I need this project in yesterday
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How about a timeline?"
                  value="I've thought about it, but no rush"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I&apos;ve thought about it, but no rush
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How about a timeline?"
                  value="Just generally planning ahead for the future"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      Just generally planning ahead for the future
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How about a timeline?"
                  value="I am looking at this for the first time today"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I am looking at this for the first time today
                    </Typography>
                  }
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        {step === 3 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                variant="h5"
                id="start"
                name="start"
                sx={
                  widget
                    ? {
                        mb: 2,
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                      }
                    : {
                        mb: 2,
                        fontSize: 24,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                How many buildings are in your portfolio?
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="How many buildings are in your portfolio?"
              >
                <FormControlLabel
                  name="How many buildings are in your portfolio?"
                  value="One"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      One
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How many buildings are in your portfolio?"
                  value="2-10"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      2-10
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How many buildings are in your portfolio?"
                  value="11-50"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      11-50
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="How many buildings are in your portfolio?"
                  value="50+"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      50+
                    </Typography>
                  }
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        {step === 4 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                variant="h5"
                id="start"
                name="start"
                sx={
                  widget
                    ? {
                        mb: 2,
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                      }
                    : {
                        mb: 2,
                        fontSize: 24,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                What are we working with?
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="What are we working with?"
              >
                <FormControlLabel
                  name="What are we working with?"
                  value="I own all my buildings."
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I own all my buildings.
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What are we working with?"
                  value="I lease/manage a building"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I lease all my buildings.
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What are we working with?"
                  value="I own some and lease some"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I own some and lease some.
                    </Typography>
                  }
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        {step === 5 && (
          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <FormControl sx={{ p: 4 }}>
              <Typography
                id="start"
                variant="h5"
                name="start"
                sx={
                  widget
                    ? {
                        mb: 2,
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                      }
                    : {
                        mb: 2,
                        fontSize: 24,
                        color: style.blueText,
                        fontWeight: 600,
                      }
                }
              >
                What do you want from us?
              </Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="What do you want from us?"
              >
                <FormControlLabel
                  name="What do you want from us?"
                  value="We need help from start to finish"
                  onChange={handleChange}
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      We need help from start to finish{" "}
                    </Typography>
                  }
                />
                <FormControlLabel
                  name="What do you want from us?"
                  value="I need some support, but keep us involved"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I need some support, but keep us involved{" "}
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What do you want from us?"
                  value="I'll list my property myself and use your marketplace"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I&apos;ll list my property myself and use your marketplace{" "}
                    </Typography>
                  }
                  onChange={handleChange}
                />
                <FormControlLabel
                  name="What do you want from us?"
                  value="I don't know, you tell me"
                  control={<Radio sx={{ color: textColor() }} />}
                  label={
                    <Typography
                      variant="h7"
                      color={widget ? "black" : "white"}
                      sx={
                        widget
                          ? { fontFamily: "Mulish", fontSize: "18px" }
                          : { fontWeight: 600 }
                      }
                    >
                      I don&apos;t know, you tell me
                    </Typography>
                  }
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
      </Box>

      <MobileStepper
        variant="progress"
        steps={7}
        activeStep={activeStep}
        sx={
          widget
            ? {
                bgcolor: "transparent",
                bottom: 15,
                // maxWidth: 400,
                margin: "auto",
              }
            : {
                bgcolor: "transparent",
                bottom: 15,
                maxWidth: 400,
                margin: "auto",
              }
        }
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === 9}
            sx={
              widget
                ? { fontFamily: "Mulish" }
                : {
                    fontSize: 24,
                    color: style.blueText,
                    fontWeight: 600,
                    textTransform: "none",
                  }
            }
          >
            Next
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : ( */}
            <KeyboardArrowRight />
            {/* {step} {activeStep} */}
            {/* )} */}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            // disabled={step === 0}
            sx={
              widget
                ? { fontFamily: "Mulish" }
                : {
                    fontSize: 24,
                    color: style.blueText,
                    fontWeight: 600,
                    textTransform: "none",
                  }
            }
          >
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : ( */}
            <KeyboardArrowLeft />
            {/* )} */}
            Back
          </Button>
        }
      />
    </Box>
  );
}

function DiscoverSavings(props) {
  let { widget, matchesMobile, setBuildingId, setEstimates } = props;
  //let widget = props.widget;
  //let matchesMobile = props.matchesMobile;
  const [address, setAddress] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeAddress = useCallback((e, value) => {
    if (e.target.value) setAddress(e.target.value);
    if (value) setAddress(value);
  }, []);
  const createProject = async () => {
    const response = await agent.Address.geocode(address);
    const result = response.data?.data?.[0].id;
    console.log(result, "result xyz");
    // const postBody = {
    //   name: "My first project",
    //   buildings: [result],
    // };
    // console.log(postBody, "postbody in discover savings");
    // const res = await agent.Project.createProject(postBody);
    // setBuildingId(res.data?.data?.id);
    setBuildingId(result);
  };
  const handleChangeBuildingType = useCallback((e) => {
    setBuildingType(e.target.value);
  }, []);

  const discoverSavings = async () => {
    try {
      createProject();
      const res = await stagingApi({
        method: httpMethod.get,
        url:
          apiRoute.estimates +
          `?address=${address}&type=${buildingType}&key=${gridmarketStagingKey}`,
      });
      let data = {
        co2: res.data.data.emissions_annual_co2_kg_reduced,
        revenue: res.data.data.total_1st_year_net_revenue,
        address: address,
      };
      // props.setEstimatesFromDiscoverSavings(data);
      setEstimates(data);
      props.navigateTo(components.SurveyQuestions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <Typography
        // variant="h3"
        color={widget ? "black" : "white"}
        fontSize={widget ? "18px" : null}
        textAlign="center"
        fontFamily={widget ? "Mulish" : null}
        sx={widget ? { fontWeight: 700 } : null}
      >
        Assess the savings and sustainability potential for your property
      </Typography>
      <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
        <PropertyAddress value={address} onChange={handleChangeAddress} />
        {/* <PropertyBuildingType value={buildingType} onChange={handleChangeBuildingType}/> */}
        <div style={style.butttonWrapper}>
          <LoadingButton
            variant="contained"
            loading={loading}
            size="small"
            sx={style.button}
            disabled={!address}
            onClick={discoverSavings}
          >
            Discover your savings
          </LoadingButton>
        </div>
      </Stack>
    </React.Fragment>
  );
}

function GetStarted(props) {
  // let matchesMobile = props.matchesMobile;
  // const estimates = props.data;
  // const buildingId = props.buildingId;
  // const widget = props.widget;
  // const form = props.form;
  const { estimates, buildingId, widget, form, setForm, matchesMobile } = props;
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const airtableApiKey = process.env.REACT_APP_AIRTABLE_KEY;
  const gridmarketApiKey = process.env.REACT_APP_GRIDMARKET_KEY;
  const Airtable = require("airtable");
  const base = new Airtable({
    apiKey: airtableApiKey,
  }).base("appGItvPPik8JRDYv");

  const submit = async () => {
    // setForm({ ...form, email });

    console.log(form, "form in submit", email);

    base("Website Forms").create(
      [
        {
          fields: {
            "Source Element": "Onboarding.js",
            Email: email,
            "How about a timeline": form["How about a timeline?"],
            "What is your role": form["What is your role?"],
            "What are we working with": form["What are we working with?"],
            "How many buildings are in your portfolio":
              form["How many buildings are in your portfolio?"],
            "What do you want from us": form["What do you want from us?"],
            "Lets get started": form["Let's get started!"][0],
            address: estimates.address,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });
      }
    );
    setLoading(true);
    setEmailSent(true);
    //addEmail();
    const registrationForm = {
      email: email,
      password: null,
      building_ids: [buildingId],
      source: "marketplace",
    };
    // const postBody = {
    //   name: "My first project",
    //   buildings: [buildingId],
    // };
    // let response = "";
    try {
      // response = await agent.Project.createProject(postBody);
      // if (response.data?.data?.group_id) {
      await agent.User.signup(registrationForm);
      // window.location.href = `https://staging.gridmarket.com/project/${buildingId}`;
    } catch (error) {
      setLoading(false);
      console.log("error -->", error);
    }
  };

  return (
    <>
      {!emailSent && (
        <>
          <Button
            variant="text"
            startIcon={<WestIcon />}
            sx={
              widget
                ? {
                    fontFamily: "Mulish",
                    left: 0,
                    textTransform: "none",
                    width: "max-content",
                  }
                : style.backButton
            }
            onClick={() => props.navigateTo(components.discoverSavings)}
          >
            Check another property
          </Button>
          <Typography
            component={"div"}
            variant="h6"
            fontWeight={600}
            color={widget ? "black" : "white"}
            sx={
              widget
                ? { fontFamily: "Mulish", fontSize: "18px", fontWeight: 800 }
                : null
            }
          >
            We think you could save around{" "}
            <Typography
              component={"span"}
              variant="h6"
              fontWeight={600}
              sx={
                widget
                  ? { fontFamily: "Mulish", fontSize: "18px", fontWeight: 800 }
                  : null
              }
              color={widget ? "#077B8F" : style.blueText}
            >
              ${formatter(estimates.revenue)}
            </Typography>{" "}
            a year and reduce your C02 footprint by{" "}
            <Typography
              component={"span"}
              variant="h6"
              fontWeight={600}
              sx={
                widget
                  ? { fontFamily: "Mulish", fontSize: "18px", fontWeight: 800 }
                  : null
              }
              color={widget ? "#077B8F" : style.blueText}
            >
              {formatter(estimates.co2)} kg/year
            </Typography>
            .
          </Typography>
          <Stack spacing={1}>
            <Typography
              variant="h6"
              color={widget ? "black" : "white"}
              fontWeight={600}
              sx={
                widget
                  ? { fontFamily: "Mulish", fontSize: "18px", fontWeight: 800 }
                  : null
              }
            >
              Enter your email address to learn more and start your RFP process
            </Typography>
            <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
              <Box sx={style.fieldWrapper}>
                <TextField
                  id="email"
                  label="Email"
                  variant="filled"
                  fullWidth
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              {/* <div style={style.butttonWrapper}> */}
              <LoadingButton
                variant="contained"
                size="small"
                loading={loading}
                sx={
                  widget
                    ? {
                        fontFamily: "Mulish",
                        fontSize: "18px",
                        fontWeight: 800,
                        textTransform: "none",
                      }
                    : style.button
                }
                disabled={!email}
                onClick={submit}
              >
                Next
              </LoadingButton>
              {/* </div> */}
            </Stack>
          </Stack>
        </>
      )}
      {emailSent && (
        <Typography
          variant="h5"
          fontWeight={600}
          sx={widget ? { fontFamily: "Mulish" } : style.blueText}
          color={widget ? "black" : "white"}
          textAlign={"center"}
        >
          Thanks! <br /> <br /> A GridMarket team member will contact you with
          more info.
        </Typography>
      )}
    </>
  );
}

function PropertyAddress({ value, onChange }) {
  const address = useDebounce(value, 600);
  const [suggestion, setSuggestion] = useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await agent.Address.autocomplete(address);
      setSuggestion(res.data.data);
    })();
  }, [address]);

  return (
    <Box sx={style.fieldWrapper}>
      <Autocomplete
        freeSolo
        fullWidth
        size="small"
        id="property-address"
        disableClearable
        options={suggestion}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            value={value}
            onChange={onChange}
            variant="filled"
            label="Property Address"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Box>
  );
}

function PropertyBuildingType({ value, onChange }) {
  return (
    <Box sx={style.fieldWrapper}>
      <FormControl variant="filled" size="small" fullWidth>
        <InputLabel id="pbt-label">Property Building Type</InputLabel>
        <Select
          labelId="pbt-label"
          id="property-building-type"
          value={value}
          label="Property Building Type"
          onChange={onChange}
        >
          <MenuItem value={"Residential"}>Residential</MenuItem>
          <MenuItem value={"Commercial"}>Commercial</MenuItem>
          <MenuItem value={"Industrial"}>Industrial</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
