import React from "react";
import { Tooltip } from "@mui/material";

import microgrid from "../../assets/microgrid.svg";
import microgridOne from "../../assets/microgridOne.svg";

import pv from "../../assets/pv.svg";
import pvAlt from "../../assets/pvAlt.svg";
import pvOne from "../../assets/pvOne.svg";
import pvOutline from "../../assets/pvOutline.svg";

import chp from "../../assets/chp.svg";
import chpAlt from "../../assets/chpAlt.svg";
import chpOne from "../../assets/chpOne.svg";
import chpOutline from "../../assets/chpOutline.svg";

import battery from "../../assets/battery.svg";
import batteryAlt from "../../assets/batteryAlt.svg";
import batteryOne from "../../assets/batteryOne.svg";
import batteryOutline from "../../assets/batteryOutline.svg";

import hydrogen from "../../assets/hydrogen.svg";
import hydrogenAlt from "../../assets/hydrogenAlt.svg";
import hydrogenOne from "../../assets/hydrogenOne.svg";
import hydrogenOutline from "../../assets/hydrogenOutline.svg";

import vppa from "../../assets/vppa.svg";
import vppaAlt from "../../assets/vppaAlt.svg";
import vppaOne from "../../assets/vppaOne.svg";
import vppaOutline from "../../assets/vppaOutline.svg";

import iot from "../../assets/iot.svg";
import iotAlt from "../../assets/iotAlt.svg";
import iotOne from "../../assets/iotOne.svg";
import iotOutline from "../../assets/iotOutline.svg";

import wind from "../../assets/wind.svg";
import windAlt from "../../assets/windAlt.svg";
import windOne from "../../assets/windOne.svg";
import windOutline from "../../assets/windOutline.svg";

import efficiency from "../../assets/efficiency.svg";
import efficiencyAlt from "../../assets/efficiencyAlt.svg";
import efficiencyOne from "../../assets/efficiencyOne.svg";
import efficiencyOutline from "../../assets/efficiencyOutline.svg";

import evCharging from "../../assets/evCharging.svg";
import evChargingAlt from "../../assets/evChargingAlt.svg";
import evChargingOne from "../../assets/evChargingOne.svg";
import evChargingOutline from "../../assets/evChargingOutline.svg";

export default function RecommendationIcon({
  type,
  value,
  tooltip,
  solid,
  label,
  iconType,
}) {
  const [tooltipValue, setTooltipValue] = React.useState("");
  const colors = {
    storage: "#05D748",
    chp: "#ff6c00",
    fuel_cell: "#01A3FF",
    solar: "#ffbc00",
  };
  const color = colors[type];

  const strokeDasharray = [
    "15.07964496 999",
    "15.07964496 1.6587609456 15.07964496 999",
    "15.07964496 1.6587609456",
  ];

  if (
    type === "solar" &&
    tooltipValue !== `${label} ${value * 0.75} kWh - ${value * 1.25} kWh`
  ) {
    setTooltipValue(`${label} ${value * 0.75} kWh - ${value * 1.25} kWh`);
  }
  if (
    type === "storage" &&
    tooltipValue !==
      `${label} ${value?.batteryKw} kW - ${value?.batteryKwH} kWh`
  ) {
    setTooltipValue(
      `${label} ${value?.batteryKw} kW - ${value?.batteryKwH} kWh`
    );
  }
  if (
    type === "chp" &&
    tooltipValue !== `${label} ${value * 0.75} kWh - ${value * 1.25} kWh`
  ) {
    setTooltipValue(`${label} ${value * 0.75} kWh - ${value * 1.25} kWh`);
  }
  if (
    type === "microgrid" &&
    tooltipValue !== label.slice(0, label.length - 1)
  ) {
    setTooltipValue(label.slice(0, label.length - 1));
  }
  if (
    type === "IOT & Controls" &&
    tooltipValue !== label.slice(0, label.length - 1)
  ) {
    setTooltipValue(label.slice(0, label.length - 1));
  }
  if (type === "vppa" && tooltipValue !== label.slice(0, label.length - 1)) {
    setTooltipValue(label.slice(0, label.length - 1));
  }

  if (
    type === "evcharging" &&
    tooltipValue !== label.slice(0, label.length - 1)
  ) {
    setTooltipValue(label.slice(0, label.length - 1));
  }

  if (type === "wind" && tooltipValue !== label.slice(0, label.length - 1)) {
    setTooltipValue(label.slice(0, label.length - 1));
  }
  if (
    type === "efficiency" &&
    tooltipValue !== label.slice(0, label.length - 1)
  ) {
    setTooltipValue(label.slice(0, label.length - 1));
  }

  const iconName = (icon) => {
    if (iconType === "old") {
      return icon;
    } else if (iconType === "alt") {
      return `${icon}Alt`;
    } else if (iconType === "one") {
      return `${icon}One`;
    } else if (iconType === "outline") {
      return `${icon}Outline`;
    }
  };

  return (
    <Tooltip title={tooltip ? `${tooltipValue}` : null} placement="top">
      <div>
        {solid ? (
          <div>
            {/* {type === "solar" && <img src={`${pv}${iconType}`} alt="" />} */}
            {type === "solar" && <img src={pvOne} alt="u" />}

            {/* {type === "storage" && <img src={`${battery}${iconType}`} alt="" />}
            {type === "chp" && <img src={`${chp}${iconType}`} alt="" />}
            {type === "fuel_cell" && (
              <img src={`${hydrogen}${iconType}`} alt="" />
            )}
            {type === "microgrid" && (
              <img src={`${microgrid}${iconType}`} alt="" />
            )}
            {type === "vppa" && <img src={`${vppa}${iconType}`} alt="" />}
            {type === "IOT & Controls" && (
              <img src={`${iot}${iconType}`} alt="" />
            )}
            {type === "wind" && <img src={`${wind}${iconType}`} alt="" />}
            {type === "efficiency" && (
              <img src={`${efficiency}${iconType}`} alt="" />
            )}
            {type === "evcharging" && (
              <img src={`${evCharging}${iconType}`} alt="" />
            )} */}
            {type === "storage" && <img src={batteryOne} alt="" />}
            {type === "chp" && <img src={chpOne} alt="" />}
            {type === "fuel_cell" && <img src={hydrogenOne} alt="" />}
            {type === "microgrid" && <img src={microgridOne} alt="" />}
            {type === "vppa" && <img src={vppaOne} alt="" />}
            {type === "IOT & Controls" && <img src={iotOne} alt="" />}
            {type === "wind" && <img src={windOne} alt="" />}
            {type === "efficiency" && <img src={efficiencyOne} alt="" />}
            {type === "evcharging" && <img src={evChargingOne} alt="" />}
          </div>
        ) : (
          <svg viewBox="0 0 18 18" fill={color}>
            <g transform="rotate(-120 7.098 1.902)">
              <circle
                r="8"
                fill="none"
                stroke={color}
                strokeWidth="1.5"
                opacity="0.2"
                strokeDashoffset="-5.03"
                strokeDasharray="15.07964496 1.6587609456"
              />
              {value && (
                <circle
                  r="8"
                  stroke={color}
                  fill="none"
                  strokeWidth="1.5"
                  strokeDashoffset="-5.03"
                  strokeDasharray={strokeDasharray[value - 1]}
                />
              )}
            </g>
            {type === "storage" && (
              <path
                transform="translate(-1.5 -.5)"
                d="M12.76 6.282c-.092-.09-.234-.136-.425-.136h-.881v-.417c0-.199-.044-.346-.132-.441-.092-.092-.234-.137-.426-.137h-.803c-.192 0-.334.045-.426.137-.087.095-.131.242-.131.441v.417h-.881c-.192 0-.334.045-.426.136-.088.096-.132.243-.132.442v5.807c0 .199.044.346.132.441.092.091.234.137.426.137h3.68c.191 0 .333-.045.425-.137.088-.095.132-.242.132-.441V6.724c0-.2-.044-.346-.132-.442"
              />
            )}
            {type === "fuel_cell" && (
              <path
                transform="translate(-1.5 -.5)"
                d="M10.6 4.5s2.11 2.045 2.858 2.775a4.208 4.208 0 011.264 3.023c0 2.326-1.847 4.202-4.111 4.202-2.275 0-4.111-1.888-4.111-4.202 0-1.169.462-2.202 1.198-2.967C8.435 6.567 10.6 4.5 10.6 4.5zm-.856 3.706h-.942v4.284h.942v-1.848h1.734v1.848h.942V8.206h-.942V9.85H9.744V8.206z"
              />
            )}
            {type === "chp" && (
              <path
                transform="translate(-1.5 -.5)"
                d="M11.827 4c-1.403 2.354.836 3.66.836 5.892 0-.744.77-1.028.41-2.465.923 1.001 1.782 4.926-1.643 6.573-.626.177-1.473.177-2.054 0-2.853-.822-4.336-4.314-.83-7.806-.558 1.02.205 2.512.42 2.876 0-3.287 2.861-5.07 2.861-5.07zm-.107 3.41l-3.086 3.252.968-.008-1.012 2.757 3.152-3.26h-1.036l1.014-2.74z"
              />
            )}
            {type === "solar" && (
              <path
                transform="translate(.15)"
                d="M8.844 12.264c.312.002.312.031.312.413v.914c-.002.296-.025.323-.305.325h-.176c-.284-.002-.31-.026-.312-.317v-.922c0-.382 0-.41.312-.413h.17zm-2.327-.97c.254.268.277.292.069.513l-.07.072-.56.585c-.282.291-.282.291-.562 0-.254-.265-.277-.289-.07-.511l.07-.074.561-.584c.28-.292.28-.292.562 0zm4.977-.071l.07.072.56.584c.28.294.28.294 0 .585-.253.265-.276.29-.49.072l-.07-.072-.562-.585c-.28-.29-.28-.29 0-.584.256-.265.28-.29.492-.072zM8.76 6.479c1.314 0 2.38 1.11 2.38 2.48 0 1.368-1.066 2.478-2.38 2.478-1.315 0-2.38-1.11-2.38-2.479S7.445 6.48 8.76 6.48zm4.447 2.066c.312.002.312.032.312.413 0 .382 0 .411-.312.413h-.963c-.283-.002-.309-.026-.311-.317V8.87c.002-.295.025-.322.304-.325h.97zm-8.81 0h.878c.283.002.309.027.311.318v.183c-.002.296-.025.323-.304.325h-.97c-.284-.002-.31-.026-.312-.317V8.87c.002-.295.026-.322.305-.325h.97zm7.728-3.093c.254.265.277.29.07.512l-.07.073-.561.585c-.28.291-.28.291-.562 0-.254-.267-.277-.291-.069-.512l.07-.073.56-.585c.282-.291.282-.291.562 0zm-6.24-.072l.07.072.562.585c.28.291.28.291 0 .585-.256.265-.28.289-.492.072l-.07-.072-.56-.585c-.28-.293-.28-.293 0-.585.253-.264.276-.289.49-.072zM8.845 4c.311.002.311.032.311.413v.915c-.002.295-.025.322-.305.325h-.176c-.284-.002-.31-.027-.312-.318v-.922c0-.381 0-.41.312-.413h.17z"
              />
            )}
          </svg>
        )}
      </div>
    </Tooltip>
  );
}
