import React from "react";
import BuildingDetailsComponent from "../components/building/buildingDetails";
import NavComponent from "../components/nav/nav";

function ListingProfilePage() {
  return (
    <>
      <NavComponent />
      <BuildingDetailsComponent />
    </>
  );
}

export default React.memo(ListingProfilePage);
