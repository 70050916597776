/* eslint-disable camelcase */

import { metersToMiles, formatNumber } from "./utils";

const none = "n/a";
const sf = " ft\xB2";
const areaUnit = "ft\xB2";
const units = "imperial";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const formatArea = (n) => {
  let area = n;
  if (units === "international") {
    area /= 10.7639104;
  }
  area = area.toLocaleString("en-US");
  return `${area} ${areaUnit}`;
};

const formatAddress = (arr) => {
  if (arr) {
    return arr[0];
  }
  return none;
};

export const formatBuildingDetails = ({
  annual_peak = none,
  annual_property_tax,
  aquisition_date,
  aquisition_price,
  area_in_sq_meters,
  average_annual_irradiance,
  billable_assessed_value,
  bins,
  boiler_capacity = none,
  boiler_consumption,
  boiler_installation_date = none,
  boiler_is_dual_fuel,
  boiler_model,
  building_far = none,
  building_name,
  burner_model = none,
  cdd65,
  co2_reduction,
  compliance_date = none,
  construction,
  customer_cd = none,
  debt,
  demo,
  der_installation_date = none,
  der_installer = none,
  der_natural_gas_consumption = none,
  der_output = none,
  der_ownership_structure = none,
  der_type = none,
  ele,
  electric_utility = null,
  electricity_forecast = null,
  electricity_use,
  energy_star_score = none,
  esimated_boiler_retirement_year = none,
  estimated_market_value,
  eui = none,
  floor_area_commercial,
  floor_area_factory,
  floor_area_garage,
  floor_area_leasable,
  floor_area_lot,
  floor_area_office,
  floor_area_other,
  floor_area_residential,
  floor_area_storage,
  floor_area_total,
  floors = none,
  gas_comm_price,
  gas_customers,
  gas_ind_price,
  gas_name,
  gas_res_price,
  gas_total_vol,
  gas_website,
  ghg_emission_total,
  hdd65,
  incentives,
  industry = none,
  iso_avg_load,
  iso_name,
  iso_peak_load,
  iso_website,
  land_use = none,
  landmark,
  last_renovation,
  lender = none,
  loan_year = none,
  monthly_predictions = null,
  natural_gas_consumption,
  natural_gas_utility,
  nearest_station,
  number_of_baths = none,
  number_of_beds = none,
  number_of_buildings = none,
  operating_expense,
  opta,
  owner_name = none,
  owner_type,
  owner_type_code = none,
  predictions = null,
  predictionsTrace,
  primary_fuel = none,
  property_name,
  pvout,
  pvwattsTrace,
  residential_units = none,
  revenue,
  share_in_aquisition_price,
  sub_category = none,
  tax_rate = none,
  temp,
  total_gallons_high,
  total_gallons_low,
  total_mmbtu_high,
  total_mmbtu_low,
  utility_customers,
  utility_name,
  utility_network_demand_peak = none,
  utility_total_mwh,
  utility_website,
  year_built = none,
  zoning = none,
  metrics,
  us_state_region,
  property_type_long,
  // ev_charger_count,
  // ev_charger_count_final,
  ev_level2_charger_count,
  ev_dcfc_charger_count,
  ev_registration_count,
  ev_registration_count_per_population,
  ev_state_population,
  ev_closest_station_name,
  ev_chargers_in_10_mile_radius,
  ev_chargers_in_state,
  ev_distance_to_substation_meters,
}) => {
  const details = {
    predictions:
      (predictions && predictions.length) ||
      (monthly_predictions && monthly_predictions.length),
    load_curve:
      (pvwattsTrace && pvwattsTrace.length) ||
      (predictionsTrace && predictionsTrace.length),
    energy_use: [
      {
        id: "annual_consumption",
        label: "Annual Consumption",
        value: formatNumber(metrics?.annual_kwh_consumption) || none,
        suffix: "kWh",
      },
      {
        label: "Region",
        value: us_state_region || none,
      },
      {
        label: "Area",
        value: area_in_sq_meters
          ? formatArea(Math.floor(area_in_sq_meters * 10.7639104))
          : none,
      },
      {
        label: "Property type",
        value: property_type_long,
      },
    ],
    utility_territory: [
      {
        label: "Name",
        value: utility_name ? toTitleCase(utility_name) : none,
      },
      {
        label: "Website",
        url: utility_website,
        value: utility_website || none,
      },
      {
        label: "Customers",
        value: utility_customers ? formatNumber(utility_customers) : none,
      },
      {
        label: "Total MWh",
        value: utility_total_mwh ? formatNumber(utility_total_mwh) : none,
      },
    ],
    ev: [
      {
        label: "Level 2 EV charger recommendation",
        suffix: "chargers",
        hideIfZero: true,
        value: ev_level2_charger_count,
      },
      {
        label: "Level 3 (DC Fast) charger recommendation",
        suffix: "chargers",
        hideIfZero: true,
        value: ev_dcfc_charger_count,
      },
      {
        label: "EV Registrations per state",
        suffix: "vehicles",
        value: ev_registration_count,
      },
      {
        label: "EV Registrations Per 1,000 People",
        value: ev_registration_count_per_population,
      },
      {
        label: "State Population",
        value: ev_state_population,
      },

      {
        label: "Closest EV Charger",
        value: ev_closest_station_name,
      },

      {
        label: "Number of EV Chargers within 10 Miles",
        value: ev_chargers_in_10_mile_radius,
      },

      {
        label: "Distance to Substation ",
        value: metersToMiles(ev_distance_to_substation_meters),
        suffix: "miles",
      },

      {
        label: "Number of EV Chargers in State",
        value: ev_chargers_in_state,
      },
    ],
    iso_territory: [
      {
        label: "Name",
        value: iso_name ? toTitleCase(iso_name) : none,
      },
      {
        label: "Website",
        url: iso_website,
        value: iso_website || none,
      },
      {
        label: "Peak Load",
        suffix: "MW",
        value: iso_peak_load ? formatNumber(iso_peak_load) : none,
      },
      {
        label: "Average Load",
        suffix: "MW",
        value: iso_avg_load ? formatNumber(iso_avg_load) : none,
      },
    ],
    natural_gas_service: [
      {
        label: "Utility Name",
        position: "left",
        value: gas_name ? toTitleCase(gas_name) : none,
      },
      {
        label: "Website",
        position: "left",
        url: gas_website,
        value: gas_website || none,
      },
      {
        label: "Total Volume (ccf)",
        position: "left",
        value: gas_total_vol ? formatNumber(gas_total_vol) : none,
      },
      {
        label: "Commercial Customers",
        position: "left",
        value: gas_customers ? formatNumber(gas_customers) : none,
      },
      {
        label: "Residential price $/MMBTU",
        position: "right",
        format: "currency",
        value: gas_res_price || none,
      },
      {
        label: "Commercial price $/MMBTU",
        position: "right",
        format: "currency",
        value: gas_comm_price || none,
      },
      {
        label: "Industrial price $/MMBTU",
        position: "right",
        format: "currency",
        value: gas_ind_price || none,
      },
    ],
    closest_power_plant: [""], // do not remove
    power_line: [""], // do not remove
    building: [
      {
        label: "Lot Area",
        position: "left",
        value: floor_area_lot ? formatArea(floor_area_lot) : none,
      },
      {
        label: "Year Built",
        position: "left",
        value: year_built,
        format: false,
      },
      {
        label: "Number Of Floors",
        position: "left",
        value: floors,
      },
      {
        label: "Floor Area Ratio",
        position: "left",
        value: building_far,
      },
      {
        label: "Number Of Buildings",
        position: "left",
        value: number_of_buildings,
      },
      {
        label: "Number Of Bedrooms",
        position: "left",
        value: number_of_beds,
      },
      {
        label: "Number Of Bathrooms",
        position: "left",
        value: number_of_baths,
      },
      {
        label: "Bin Numbers",
        position: "left",
        value: bins && bins.length ? bins.join(", ") : none,
      },
      {
        label: "Construction",
        position: "left",
        value: construction && construction.length ? construction : none,
      },
      {
        label: "Nearest Station",
        position: "left",
        value:
          nearest_station && nearest_station.length ? nearest_station : none,
      },
      {
        label: "Property Name",
        position: "left",
        value: property_name && property_name.length ? property_name : none,
      },
      {
        label: "Building Name",
        position: "left",
        value: building_name && building_name.length ? building_name : none,
      },
      {
        label: "Acquisition Date",
        position: "left",
        value:
          aquisition_date && aquisition_date.length ? aquisition_date : none,
      },
      {
        label: "Acquisition price",
        position: "left",
        value:
          aquisition_price && aquisition_price.length ? aquisition_price : none,
      },
      {
        label: "Share in acquisition price",
        position: "left",
        value:
          share_in_aquisition_price && share_in_aquisition_price.length
            ? share_in_aquisition_price
            : none,
      },
      {
        label: "Industry",
        position: "left",
        value: industry,
      },
      {
        label: "Subcategory",
        position: "left",
        value: sub_category,
      },
      {
        label: "Customer cd",
        position: "left",
        value: customer_cd,
      },
      {
        label: "Total Area",
        position: "right",
        value: floor_area_total != null ? formatArea(floor_area_total) : none,
      },
      {
        label: "Office Area",
        position: "right",
        value: floor_area_office != null ? formatArea(floor_area_office) : none,
      },
      {
        label: "Commercial Area",
        position: "right",
        value:
          floor_area_commercial != null
            ? formatArea(floor_area_commercial)
            : none,
      },
      {
        label: "Zoning Code",
        position: "right",
        value: zoning,
      },
      {
        label: "Factory Area",
        position: "right",
        value:
          floor_area_factory != null ? formatArea(floor_area_factory) : none,
      },
      {
        label: "Storage Area",
        position: "right",
        value:
          floor_area_storage != null ? formatArea(floor_area_storage) : none,
      },
      {
        label: "Residential Area",
        position: "right",
        value:
          floor_area_residential != null
            ? formatArea(floor_area_residential)
            : none,
      },
      {
        label: "Garage Area",
        position: "right",
        value: floor_area_garage != null ? formatArea(floor_area_garage) : none,
      },
      {
        label: "Roof Area",
        position: "right",
        value: area_in_sq_meters
          ? formatArea(Math.floor(area_in_sq_meters * 10.7639104))
          : none,
      },
      {
        label: "Leasable Area",
        position: "right",
        value: floor_area_leasable
          ? formatArea(Math.floor(floor_area_leasable))
          : none,
      },
      {
        label: "Other Area",
        position: "right",
        value: floor_area_other != null ? formatArea(floor_area_other) : none,
      },
      {
        label: "Land Use",
        position: "right",
        value: land_use,
      },
      {
        label: "Residential Units",
        position: "right",
        value: residential_units,
      },
    ],
    financials: [
      {
        label: "Market Value",
        position: "left",
        format: "currency",
        value: estimated_market_value || none,
      },
      {
        label: "Tax Rate",
        position: "left",
        suffix: "%",
        value: tax_rate || none,
      },
      {
        label: "Revenue",
        position: "left",
        format: "currency",
        value: revenue || none,
      },
      {
        label: "OPEX",
        position: "left",
        format: "currency",
        value: operating_expense || none,
      },
      {
        label: "Net Operating Income",
        position: "left",
        format: "currency",
        value:
          revenue && operating_expense ? revenue - operating_expense : none,
      },
      {
        label: "Taxable Value",
        format: "currency",
        position: "right",
        value: billable_assessed_value || none,
      },
      {
        label: "Property Tax",
        format: "currency",
        position: "right",
        value: annual_property_tax != null ? annual_property_tax : none,
      },
      {
        label: "Lender",
        position: "right",
        value: lender || none,
      },
      {
        label: "Loan",
        format: "currency",
        position: "right",
        value: debt || none,
      },
      {
        label: "Loan Year",
        position: "right",
        value: loan_year || none,
      },
    ],
    energy: [
      {
        label: "HDD65",
        suffix: "days",
        values: hdd65 || none,
      },
      {
        label: "Average Annual Irradiance",
        suffix: "kWh/m<sup>2</sup>/day",
        value: average_annual_irradiance || none,
      },
      {
        label: "CDD65",
        suffix: "days",
        value: cdd65 || none,
      },

      {
        label: "Energy Use Intensity",
        suffix: `kBtu/${sf}`,
        value: eui,
      },
      {
        label: "energy star rating",
        value: energy_star_score,
      },
      {
        label: "total ghg emissions",
        suffix: "MtCO2e",
        value: ghg_emission_total ? formatNumber(ghg_emission_total) : none,
      },
      {
        label: "annual kwh estimate",
        suffix: "kWh",
        value: electricity_use ? formatNumber(electricity_use) : none,
      },
      {
        label: "annual peak",
        suffix: "kW",
        value: annual_peak,
      },
      {
        label: "compliance(ll87)",
        value: compliance_date,
      },
      {
        label: "utility network demand peak",
        value: utility_network_demand_peak,
      },
      {
        label: "electric utility",
        value: electric_utility || none,
      },
      {
        label: "natural gas utility",
        value: natural_gas_utility || none,
      },
    ],
    wind_speed: true, // do not remove
    temperature: true, // do not remove
    electricity_forecast: !!electricity_forecast, // TODO: Add real data?
    der: [
      {
        label: "Type",
        position: "left",
        value: der_type,
      },
      {
        label: "Output",
        position: "left",
        suffix: "kW",
        value: der_output,
      },
      {
        label: "installer & manufacturer",
        position: "right",
        value: der_installer,
      },
      {
        label: "ownership structure",
        position: "right",
        value: der_ownership_structure,
      },
      {
        label: "install date",
        position: "right",
        value: der_installation_date,
      },
      {
        label: "natural gas consumption",
        position: "right",
        value: der_natural_gas_consumption,
      },
    ],
    owner: [
      {
        label: "owner",
        value: owner_name || none,
      },
      {
        label: "type",
        value: owner_type || none,
      },
      {
        label: "type code",
        value: owner_type_code || none,
      },
    ],
  };

  // remove der section if non existing
  if (!der_output) {
    delete details.der;
  }

  // Remove energy use section if not available
  if (!metrics || !us_state_region) {
    delete details.energy_use;
  }

  if (total_mmbtu_low) {
    details.energy.push(
      {
        label: "total mmbtu",
        value: total_mmbtu_low
          ? `${formatNumber(total_mmbtu_high)} - ${formatNumber(
              total_mmbtu_low
            )}`
          : none,
      },
      {
        label: "total gallons",
        value: total_gallons_low
          ? `${formatNumber(total_gallons_low)} - ${formatNumber(
              total_gallons_high
            )}`
          : none,
      },
      {
        label: "burner model",
        value: burner_model,
      },
      {
        label: "primary fuel",
        value: primary_fuel,
      },
      {
        label: "boiler retirement year",
        value: esimated_boiler_retirement_year,
      }
    );
  }

  if (incentives) {
    details.incentives = incentives.map((incentive) => ({
      key: incentive.id,
      label: incentive.category,
      value: incentive.name,
    }));
  }

  if (natural_gas_consumption) {
    details.energy.push({
      label: "Annual Natural Gas Consumption (MMBTu)",
      value: natural_gas_consumption
        ? `${formatNumber(natural_gas_consumption)}`
        : none,
    });
  }

  const co2_technologies = ["solar", "fuel_cell", "chp", "solar_thermal"];
  co2_technologies.forEach((tech) => {
    if (!co2_reduction || !co2_reduction[tech]) {
      return;
    }
    const { low } = co2_reduction[tech];
    const { high } = co2_reduction[tech];
    if (high > 0) {
      const v = {
        label: `CO2 Reduction with ${tech.toUpperCase().replace(/_/g, " ")}`,
        suffix: "lbs of CO2 reduced/year",
        value: high ? formatNumber(low) - formatNumber(high) : none,
      };
      if (co2_reduction[tech].high <= 0) {
        v.value = "0";
      }
      details.energy.push(v);
    }
  });

  if (last_renovation) {
    details.building.push({
      label: "last renovation",
      value: last_renovation,
    });
  }

  if (landmark) {
    details.building.push({
      label: "landmark",
      value: landmark,
    });
  }

  if (boiler_model || boiler_consumption) {
    const boilerData = [
      {
        label: "boiler model",
        value: boiler_model || none,
      },
      {
        label: "boiler capacity",
        suffix: "Btu",
        value: boiler_capacity,
      },
      {
        label: "boiler installation Date",
        value: boiler_installation_date,
      },
      {
        label: "est. boiler consumption (MMBTU)",
        value: boiler_consumption || none,
      },
      {
        label: "dual fuel",
        value: boiler_is_dual_fuel ? "Yes" : "No",
      },
    ];

    details.energy.push(...boilerData);
  }

  if (demo) {
    details.building.forEach((o, i) => {
      if (o["bin number"]) {
        details.building.splice(i, 1);
      }
    });
  }

  if (opta) {
    details.energy.push({
      label: "Solar Panel Tilt",
      suffix: "°/180°",
      value: opta,
    });
  }

  if (pvout) {
    details.energy.push({
      label: "Solar Irradiance",
      suffix: "kWh/kWp per day",
      value: pvout.toFixed(2),
    });
  }

  if (ele) {
    details.building.push({
      label: "elevation",
      suffix: "m",
      value: ele,
    });
  }

  if (temp) {
    details.building.push({
      label: "temperature",
      suffix: "°C",
      value: temp.toFixed(2),
    });
  }

  Object.keys(details).forEach((propName) => {
    const skip = [
      "closest_power_plant",
      "incentives",
      "power_line",
      "predictionsTrace",
      "pvwattsTrace",
    ];
    if (Array.isArray(details[propName]) && !skip.includes(propName)) {
      const newDetails = [];
      details[propName].forEach((o) => {
        if (o.value !== none && o.value !== "NOT AVAILABLE") {
          newDetails.push(o);
        }
      });
      if (newDetails.length) {
        details[propName] = newDetails;
      } else {
        delete details[propName];
      }
    }
  });
  return details;
};

export const formatBuildingData = ({
  address,
  address_ranges,
  addresses,
  attachments,
  centroid,
  contacts,
  energy_star_score,
  estimates,
  eui,
  property_type_long,
  recommendations,
  reductions,
  therm_production = { low: 0, high: 0 },
  ...rest
}) => {
  const data = {
    ...rest,
    files: attachments,
    centroid: {
      lat: centroid[1],
      lon: centroid[0],
    },
    address: address || formatAddress(addresses) || none,
    address_ranges:
      address_ranges && address_ranges.length > 1 ? address_ranges : [],
    property_type: property_type_long,
    contacts,
    recommendations,
    pv: recommendations.pv || 0,
    chp: recommendations.chp || 0,
    storage: recommendations.storage || 0,
    fuel_cell: recommendations.fuel_cell || 0,
    solar_thermal: recommendations.solar_thermal || 0,
    icons: {
      energystar: energy_star_score,
      eui,
    },
    estimates: {
      chp: estimates.chp || none,
      fuel_cell: estimates.fuel_cell || none,
      solar: estimates.solar || none,
      storage: estimates.storage || none,
      solar_thermal: estimates.solar_thermal || none,
    },
    therm_production,
    reductions: reductions || {
      solar: {
        kw_reduction: {
          low: 0,
          high: 0,
        },
        kwh_reduction: {
          low: 0,
          high: 0,
        },
      },
      chp: {
        kw_reduction: {
          low: 0,
          high: 0,
        },
        kwh_reduction: {
          low: 0,
          high: 0,
        },
      },
      storage: {
        kw_reduction: {
          low: 0,
          high: 0,
        },
        kwh_reduction: {
          low: 0,
          high: 0,
        },
      },
      fuel_cell: {
        kw_reduction: {
          low: 0,
          high: 0,
        },
        kwh_reduction: {
          low: 0,
          high: 0,
        },
      },
    },
  };
  Object.keys(data).forEach((propName) => {
    const skip = [
      "closest_power_plant",
      "contacts",
      "files",
      "incentives",
      "power_line",
      "predictionsTrace",
      "pvwattsTrace",
      "bbox",
    ];
    const newArr = [];
    if (Array.isArray(data[propName]) && !skip.includes(propName)) {
      data[propName].forEach((o) => {
        Object.keys(o).forEach((k) => {
          const v = o[k];
          if (v !== none && v !== "NOT AVAILABLE") {
            newArr.push(o);
          }
        });
      });
      data[propName] = newArr;
    }
  });
  return data;
};

export function formatBuildingPower({
  power_line_distance_in_meters,
  power_line_id,
  power_line_length_in_meters,
  power_plant_desc,
  power_plant_distance,
  power_plant_fuel,
  power_plant_id,
  power_plant_name,
  power_plant_total_mw,
}) {
  const data = {
    closest_power_plant: false,
    power_line: false,
  };
  if (power_plant_id) {
    data.closest_power_plant = [
      {
        label: "Name",
        url: power_plant_id ? `/search/${power_plant_id}` : null,
        value: power_plant_name || none,
      },
      {
        label: "Power Output",
        suffix: "MW",
        value: power_plant_total_mw || none,
      },
      {
        label: "Type",
        value: power_plant_desc || none,
      },
      {
        label: "Distance",
        suffix: "miles",
        value: power_plant_distance
          ? metersToMiles(power_plant_distance)
          : none,
      },
      {
        label: "Fuel",
        value: power_plant_fuel || none,
      },
    ];
  }
  if (power_line_id) {
    data.power_line = [
      {
        label: "Powerline Length",
        value: power_line_length_in_meters
          ? metersToMiles(power_line_length_in_meters)
          : none,
        suffix: "miles",
      },
      {
        label: "Powerline Distance",
        value: power_line_distance_in_meters
          ? metersToMiles(power_line_distance_in_meters)
          : none,
        suffix: "miles",
      },
    ];
  }
  return data;
}

export function formatBuildingFinancials({
  IRR,
  net_present_value,
  payback_period,
  total_1st_year_net_revenue,
  total_30_year_net_revenue,
  total_gross_project_cost,
  battery_kw,
  battery_kwh,
  chp_kw,
  fuel_cell_kw,
  pv_kw,
}) {
  return [
    {
      label: "Project Cost",
      value: total_gross_project_cost,
      format: "currency",
    },
    {
      label: "Payback Period",
      value: payback_period,
      suffix: "years",
    },
    {
      label: "IRR",
      value: IRR * 100,
      suffix: "%",
    },
    {
      label: "1st Year Net Revenue",
      value: total_1st_year_net_revenue,
      format: "currency",
    },
    {
      label: "25 Year Net Revenue",
      value: total_30_year_net_revenue,
      format: "currency",
    },
    {
      label: "Net Present Value",
      value: net_present_value,
      format: "currency",
    },
    {
      label: "Battery",
      value: `${formatNumber(battery_kw)}kW / ${formatNumber(battery_kwh)}kWh`,
    },
    {
      label: "CHP",
      value: chp_kw,
      suffix: "kW",
    },
    {
      label: "Fuel Cell",
      value: fuel_cell_kw,
      suffix: "kW",
    },
    {
      label: "Solar",
      value: pv_kw,
      suffix: "kW",
    },
  ];
}

export function formatBuildingRates({
  abbreviation,
  alternative_policy,
  cost_solar,
  country_average_rate_kwh,
  country_commercial_rate_kwh,
  country_industrial_rate_kwh,
  country_residential_rate_kwh,
  country_source,
  interconnection_spr,
  name,
  net_metering_allowed,
  net_metering_capacity_limit,
  net_metering_meter_aggregation,
  net_metering_notes,
  performance_payments_spr,
  property_tax_spr,
  rps_spr,
  sales_tax_spr,
  score_spr,
  state_commercial_rate_kwh,
  state_industrial_rate_kwh,
  state_residential_rate_kwh,
  state_source,
  tax_credit_spr,
}) {
  return [
    {
      value: name,
      name: "name",
      label: "name",
    },
    {
      value: rps_spr,
      name: "rps_spr",
      label: "rps spr",
    },
    {
      value: score_spr,
      name: "score_spr",
      label: "score spr",
    },
    {
      value: cost_solar,
      name: "cost_solar",
      label: "cost solar",
      format: "currency",
    },
    {
      value: abbreviation,
      name: "abbreviation",
      label: "abbreviation",
    },
    {
      value: state_source,
      name: "state_source",
      label: "state source",
    },
    {
      value: sales_tax_spr,
      name: "sales_tax_spr",
      label: "sales taxspr",
    },
    {
      value: country_source,
      name: "country_source",
      label: "country source",
    },
    {
      value: tax_credit_spr,
      name: "tax_credit_spr",
      label: "tax credit spr",
    },
    {
      value: property_tax_spr,
      name: "property_tax_spr",
      label: "property tax spr",
    },
    {
      value: alternative_policy,
      name: "alternative_policy",
      label: "alternative policy",
    },
    {
      value: net_metering_notes,
      name: "net_metering_notes",
      label: "net metering notes",
    },
    {
      value: interconnection_spr,
      name: "interconnection_spr",
      label: "interconnection spr",
    },
    {
      value: net_metering_allowed,
      name: "net_metering_allowed",
      label: "net metering allowed",
    },
    {
      value: country_average_rate_kwh,
      name: "country_average_rate_kwh",
      label: "country average rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: performance_payments_spr,
      name: "performance_payments_spr",
      label: "performance payments spr",
    },
    {
      value: state_commercial_rate_kwh,
      name: "state_commercial_rate_kwh",
      label: "state commercial rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: state_industrial_rate_kwh,
      name: "state_industrial_rate_kwh",
      label: "state industrial rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: state_residential_rate_kwh,
      name: "state_residential_rate_kwh",
      label: "state residential rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: country_commercial_rate_kwh,
      name: "country_commercial_rate_kwh",
      label: "country commercial rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: country_industrial_rate_kwh,
      name: "country_industrial_rate_kwh",
      label: "country industrial rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: net_metering_capacity_limit,
      name: "net_metering_capacity_limit",
      label: "net metering capacity limit",
    },
    {
      value: country_residential_rate_kwh,
      name: "country_residential_rate_kwh",
      label: "country residential rate",
      prefix: "$",
      suffix: "/kWh",
    },
    {
      value: net_metering_meter_aggregation,
      name: "net_metering_meter_aggregation",
      label: "net metering meter aggregation",
    },
  ];
}
