import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Chip,
  FormControl,
  TextField,
} from "@mui/material";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

import BuildingRecommendations from "./buildingRecommendations";
import Chart from "./chart";
import UseChart from "./useChart";
import { clusterData } from "./clusterData";
import translations from "../../core/translations";
import "./building.sass";
import "./dataSection.sass";
import axios from "axios";
import { formatBuildingFinancials } from "../../core/formatData";
import DataGridItem from "../../core/DataGridItem";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "./loadingSpinner";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import MarkdownView from "react-showdown";

function BuildingDetailsComponent() {
  const location = useLocation();
  const id = location.pathname.slice(10);

  const { descriptions } = translations;
  const [airtableData, setAirtableData] = React.useState();
  const [gridmarketData, setGridmarketData] = React.useState();
  const [financialData, setFinancialData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [buildingId, setBuildingId] = React.useState();
  const [subscribeStep, setSubscribeStep] = React.useState(0);
  const [subscribeStyle, setSubscribeStyle] = React.useState("");
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [clusterId, setClusterId] = React.useState();
  const [formState, setFormState] = React.useState({ fName: "", email: "" });
  const [isMobile, setIsMobile] = React.useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const airtableApiKey = process.env.REACT_APP_AIRTABLE_KEY;
  const Airtable = require("airtable");
  const base = new Airtable({
    apiKey: airtableApiKey,
  }).base("appGItvPPik8JRDYv");

  const airtableFetch = async () => {
    await base("tblUlQZhLiEevk3CK").find(
      id,
      // {
      //   returnFieldsByFieldId: {
      //     fldKrCJvDbbCR2NK6: true,
      //   },
      // },
      function (err, record) {
        if (err) {
          console.error(err, "error in airtable fetch");
          return;
        }
        console.log(record);
        setBuildingId(record.fields.platform_id);
        const addressFromAirtable = record.fields["Address"];
        const autoName = record.fields["Auto Name"];
        const status = record.fields["Marketplace Status"];
        const statusDescription =
          record.fields["Marketplace Status Description"];
        const assetValue = record.fields["Capex: Asset Value"];
        const financingOptions = record.fields["Financing Options"];
        const description = record.fields["Listing Description"] || "";
        const co2Reduction = record.fields["CO2: 1st year Reduction (kg)"];
        const clusterId = record.fields["GM Property Type"];
        setAirtableData({
          addressFromAirtable,
          status,
          autoName,
          description,
          assetValue,
          financingOptions,
          statusDescription,
          co2Reduction,
        });
        setClusterId(clusterId);
      }
    );
  };

  const gridmarketFetch = async () => {
    const gridmarketApiKey = process.env.REACT_APP_GRIDMARKET_KEY;
    setIsLoading(true);
    const url = `https://platform.gridmarket.com/e2e-api/api/building/${buildingId}?&include_gm_property_type=1`;
    const response = await axios.get(url, {
      params: {
        key: gridmarketApiKey,
        include_financials_raw: 1,
      },
    });
    // setClusterId(response.data.data.gm_property_type || 1);
    setGridmarketData(response.data.data);
  };

  const financialDataFetch = async () => {
    const financialObjectMaker = (gridmarketData) => {
      const IRR = gridmarketData.financials_raw.IRR;
      const net_present_value = gridmarketData.financials_raw.net_present_value;
      const payback_period = gridmarketData.financial_payback_period;
      const total_1st_year_net_revenue =
        gridmarketData.financials_raw.total_1st_year_net_revenue;
      const total_30_year_net_revenue =
        gridmarketData.financials_raw.total_30_year_net_revenue;
      const total_gross_project_cost =
        gridmarketData.financials_raw.total_gross_project_cost;
      const battery_kw = gridmarketData.financials_raw.battery_kw;
      const battery_kwh = gridmarketData.financials_raw.battery_kwh;
      const chp_kw = gridmarketData.financials_raw.chp_kw;
      const fuel_cell_kw = gridmarketData.financials_raw.fuel_cell_kw;
      const pv_kw = gridmarketData.financials_raw.pv_kw;
      return {
        IRR,
        net_present_value,
        payback_period,
        total_1st_year_net_revenue,
        total_30_year_net_revenue,
        total_gross_project_cost,
        battery_kw,
        battery_kwh,
        chp_kw,
        fuel_cell_kw,
        pv_kw,
      };
    };
    if (gridmarketData !== undefined) {
      let financialObject = financialObjectMaker(gridmarketData);
      const financials = formatBuildingFinancials(financialObject);
      setFinancialData(financials);
      setIsLoading(false);
    }
  };

  const iconWidth = 70;

  const currentCluster = clusterData[clusterId];

  const allowSubmit = () => {
    if (formState.fName && formState.email) {
      setSubmitDisabled(false);
    }
  };

  const handleSubscribe = async () => {
    setSubscribeStep(1);
    setSubscribeStyle("backdropBlur");
  };

  const subscribeSubmission = async () => {
    if (subscribeStep === 1) {
      const email = formState.email;
      const name = formState.fName;
      await base("tbl9QC0FTHlP7eL35").create(
        [
          {
            fields: {
              Name: name,
              Email: email,
              buildingId: buildingId,
              Source: "Website Form",
              "Source Element": "Subscribe for updates",
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.error(err);
            return;
          }
          records.forEach(function (record) {});
        }
      );
      setSubscribeStep(2);
    }

    if (subscribeStep === 2) {
      setSubscribeStep(0);
      setSubscribeStyle("");
    }
  };

  const handleFormChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    airtableFetch();
  }, []);

  useEffect(() => {
    gridmarketFetch();
  }, [buildingId]);

  useEffect(() => {
    if (window.innerWidth > 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    financialDataFetch();
  }, [gridmarketData]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  useEffect(() => {
    allowSubmit();
  }, [formState]);

  const assetValue = (value) => {
    if (value < 500000) {
      return "Under $500K";
    }
    if (value >= 500000 && value < 1000000) {
      return "$500K - $1M";
    }
    if (value >= 1000000 && value < 2000000) {
      return "$1M - $2M";
    }
    if (value >= 2000000 && value < 5000000) {
      return "$2M - $5M";
    }
    if (value >= 5000000) {
      return "Over $5M";
    }
  };

  const twoDecimal = (num) => {
    return Math.round(num + Number.EPSILON).toLocaleString("en-US");
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className={subscribeStyle}>
        {airtableData && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ pl: 3 }}>
                <Button
                  onClick={() => navigate("/home")}
                  sx={{
                    textTransform: "none",
                    left: 0,
                    mt: 4,
                    mb: 2,
                    color: "#3aa4ff",
                  }}
                >
                  <KeyboardArrowLeftIcon />
                  Back to marketplace
                </Button>

                <Typography
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "black", ml: 2 }}
                >
                  {airtableData.autoName}
                </Typography>
                <Typography sx={{ ml: 2 }} className="blur">
                  {airtableData.addressFromAirtable}
                </Typography>
                <button
                  className="subscribeButton"
                  sx={{ ml: 2 }}
                  onClick={handleSubscribe}
                >
                  Subscribe for updates
                </button>
              </Box>

              <Grid className="statusBox">
                <Grid className="arrow-pointer" display="flex">
                  <Typography
                    alignContent="center"
                    flexWrap="wrap"
                    color="white"
                    sx={{ margin: "auto" }}
                  >
                    {airtableData.status}
                  </Typography>
                </Grid>
                <Typography
                  variant="body2"
                  color="#4d4d4d"
                  sx={{ maxWidth: 150, mt: 4, position: "absolute", right: 0 }}
                >
                  {airtableData.statusDescription}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                maxWidth: "94vw",
                margin: "auto",
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              <MarkdownView
                markdown={airtableData.description}
                options={{ tables: true, emoji: true }}
              />{" "}
            </Box>
          </>
        )}

        {gridmarketData && (
          <>
            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                DER Asset Value
              </Typography>
            </div>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                maxWidth: "100vw",
                flexWrap: "wrap",
                minHeight: 36,
                alignItems: "center",
              }}
            >
              <DataGridItem
                valueProps={{ fontWeight: "bold" }}
                value={assetValue(airtableData.assetValue)}
                sx={{
                  ml: 3.5,
                }}
              />
            </Box>
            <Box className="sectionDivider" sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Recommended Solutions
              </Typography>
            </Box>

            <BuildingRecommendations
              descriptions={descriptions}
              valueProps={{ fontWeight: "bold", maxWidth: 200 }}
              estimates={gridmarketData.estimates}
              solid
              technologies={currentCluster.technologies}
            />

            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Additional Needs
              </Typography>
            </div>
            <Box
              display="flex"
              flexDirection="row"
              sx={{ maxWidth: "100vw", flexWrap: "wrap" }}
            >
              {currentCluster.additionalNeeds.map((need) => {
                return (
                  <Box>
                    <Chip
                      label={need}
                      key={need}
                      sx={{
                        backgroundColor: "#cedede",
                        color: "black",
                        fontWeight: "bold",
                        m: 1,
                        ml: 3,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Targets
              </Typography>
            </div>
            <Grid
              direction="row"
              padding={{ xs: 7, md: 4 }}
              spacing={{ xs: 7, md: 4 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              container
            >
              {Object.keys(currentCluster.targets).map((target) => {
                return currentCluster.targets[target].value ? (
                  <Grid item xs={2} sm={2} md={3}>
                    <DataGridItem
                      valueProps={{ fontWeight: "bold" }}
                      label={currentCluster.targets[target].label}
                      labelClass="headerText"
                      value={
                        currentCluster.targets[target].label === "Resilience" &&
                        currentCluster.targets[target].value ? (
                          <CheckCircleOutlineIcon
                            sx={{
                              ml: 3.5,
                            }}
                          />
                        ) : (
                          currentCluster.targets[target].value
                        )
                      }
                    />
                  </Grid>
                ) : null;
              })}
              <Grid item xs={2} sm={2} md={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label={"CO2 Reduction kg/yr"}
                  labelClass="headerText"
                  value={airtableData.co2Reduction}
                />
              </Grid>
            </Grid>
            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Financing Recommendations
              </Typography>
            </div>
            {airtableData?.financingOptions?.includes("CapEx") && (
              <>
                <div className="subHeader">
                  <Typography sx={{ ml: 4, fontSize: 14 }}>CAPEX</Typography>
                </div>
                <Grid padding={2} container>
                  {financialData &&
                    financialData.map((item) =>
                      item.label === "Solar" ||
                      item.label === "CHP" ||
                      item.label === "Battery" ||
                      item.label === "Project Cost" ||
                      item.label === "Fuel Cell" ? null : (
                        <Grid item padding={2} xs={6} sm={4} md={2.4} lg={2.4}>
                          <DataGridItem
                            valueProps={{ fontWeight: "bold" }}
                            labelClass="headerText"
                            key={item.label}
                            {...item}
                            item
                          />
                        </Grid>
                      )
                    )}
                </Grid>
              </>
            )}
            {airtableData?.financingOptions?.includes("PPA") && (
              <>
                <div className="subHeader">
                  <Typography sx={{ ml: 4, fontSize: 14 }}>PPA</Typography>
                </div>
                <Grid padding={2} container>
                  <Grid item padding={3} xs={6} sm={4} md={3} lg={2.4}>
                    <DataGridItem
                      valueProps={{ fontWeight: "bold" }}
                      labelClass="headerText"
                      label="1ST YEAR SAVINGS"
                      md={12}
                      format={"currency"}
                      value={
                        gridmarketData.financials_raw
                          .ppa_1st_year_savings_to_customer
                      }
                    />
                  </Grid>
                  <Grid item padding={3} xs={6} sm={4} md={3} lg={2.4}>
                    <DataGridItem
                      valueProps={{ fontWeight: "bold" }}
                      labelClass="headerText"
                      label="LIFETIME BENEFIT"
                      md={12}
                      format={"currency"}
                      value={
                        gridmarketData.financials_raw
                          .ppa_20_year_savings_to_customer
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {airtableData?.financingOptions?.includes("Lease") &&
              gridmarketData.financials_raw.lease_metrics && (
                <>
                  <div className="subHeader">
                    <Typography sx={{ ml: 4, fontSize: 14 }}>Lease</Typography>
                  </div>
                  <Grid padding={2} container>
                    <Grid item padding={3} xs={6}>
                      <DataGridItem
                        valueProps={{ fontWeight: "bold" }}
                        label="LIFETIME BENEFIT"
                        labelClass="headerText"
                        md={12}
                        format={"currency"}
                        value={
                          gridmarketData.financials_raw.lease_metrics
                            .lease_1st_year_net_revenue
                        }
                      />
                    </Grid>
                    <Grid item padding={3} xs={6}>
                      <DataGridItem
                        valueProps={{ fontWeight: "bold" }}
                        label="LIFETIME BENEFIT"
                        labelClass="headerText"
                        md={12}
                        format={"currency"}
                        value={
                          gridmarketData.financials_raw.lease_metrics
                            .lease_total_net_revenue
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}

            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Estimated Environmental Benefits
              </Typography>
            </div>
            <div className="subHeader">
              <Typography sx={{ ml: 4, fontSize: 14 }}>
                Annual C02 Reduction
              </Typography>
            </div>
            <Grid padding={2} container>
              <Grid item padding={3} xs={6} sm={4} md={3} lg={3}>
                <DataGridItem
                  label={
                    <>
                      EQUIVALENT KG CO<sub>2</sub>
                    </>
                  }
                  labelClass="headerText"
                  valueProps={{ fontWeight: "bold" }}
                  md={12}
                  value={
                    gridmarketData.financials_raw.emissions
                      .annual_co2_kg_reduced
                  }
                />
              </Grid>
              <Grid item padding={3} xs={6} sm={4} md={3} lg={3}>
                <DataGridItem
                  label="EQUIVALENT GALLONS OF GAS"
                  labelClass="headerText"
                  valueProps={{ fontWeight: "bold" }}
                  md={12}
                  value={
                    gridmarketData.financials_raw.emissions
                      .gallons_of_gasoline_consumed
                  }
                />
              </Grid>
              <Grid item padding={3} xs={6} sm={4} md={3} lg={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="EQUIVALENT VEHICLES DRIVEN"
                  labelClass="headerText"
                  md={12}
                  value={
                    gridmarketData.financials_raw.emissions
                      .passenger_vehicles_driven_for_one_year
                  }
                />
              </Grid>
              <Grid item padding={3} xs={6} sm={4} md={3} lg={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="EQUIVALENT SUBURBAN HOMES"
                  labelClass="headerText"
                  md={12}
                  value={
                    gridmarketData.financials_raw.emissions
                      .average_homes_electricity_use_for_one_year
                  }
                />
              </Grid>
            </Grid>
            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Energy Utilization
              </Typography>
            </div>
            <div className="subHeader">
              <Typography sx={{ ml: 4, fontSize: 14 }}>
                Estimated Load Profile
              </Typography>
            </div>

            <Box sx={{ p: 10 }}>
              <Chart chartType={"predictions"} buildingId={buildingId} />
            </Box>
            <div className="subHeader">
              <Typography sx={{ ml: 4, fontSize: 14 }}>
                Estimated Energy Use
              </Typography>
            </div>
            <Grid padding={2} container>
              <Grid item padding={3} xs={6} sm={4} md={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="ANNUAL CONSUMPTION"
                  labelClass="headerText"
                  dataClass="dataText"
                  md={12}
                  value={gridmarketData.metrics.annual_kwh_consumption}
                />
              </Grid>

              <Grid item padding={3} xs={6} sm={4} md={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="REGION"
                  labelClass="headerText"
                  md={12}
                  value={gridmarketData.us_state_region}
                />
              </Grid>
              <Grid item padding={3} xs={6} sm={4} md={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={`${gridmarketData.area_in_sq_meters}` + ` sq. ft.`}
                />
              </Grid>
              <Grid item padding={3} xs={6} sm={4} md={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="PROPERTY TYPE"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.type}
                />
              </Grid>
            </Grid>
            <Box sx={{ pl: 20, pr: 20, pt: 5 }} className="energyUseChart">
              <UseChart
                cbecsData={gridmarketData.cbecs}
                metrics={gridmarketData.metrics}
              />
            </Box>
            <div className="sectionDivider">
              <Typography sx={{ fontWeight: "bold", ml: 2 }}>
                Building & Property
              </Typography>
            </div>
            <div className="subHeader">
              <Typography sx={{ ml: 4, fontSize: 14 }}>
                Building Data
              </Typography>
            </div>
            <Grid padding={2} container>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="YEAR BUILT"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.year_built || "1929"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="LAST RENOVATION"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.last_renovation || "1999"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="ZONING CODE"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.zoning || "Unknown"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="LOT AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.floor_area_lot || "99,999 sq ft"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="TOTAL AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.floor_area_total || "124,000 sq ft"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="COMMERCIAL AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.floor_area_commercial || "N/A"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="FACTORY AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.floor_area_factory || "N/A"}
                />
              </Grid>
              <Grid item padding={3} xs={3}>
                <DataGridItem
                  valueProps={{ fontWeight: "bold" }}
                  label="STORAGE AREA"
                  labelClass="headerText"
                  dataClass=" blur"
                  md={12}
                  value={gridmarketData.floor_area_storage || "N/A"}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Box sx={{ height: 70 }}></Box>
        <Box
          className="bottomGradient"
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "min-content",
          }}
        >
          <Box
            sx={{
              width: "min-content",
              m: "auto",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "600px",
            }}
          >
            <Typography
              sx={{
                ml: "auto",
                mr: "auto",
                mt: 6,
                color: "white",
                width: "max-content",
                fontSize: "2rem",
                fontFamily: "Archivo",
                fontWeight: "bold",
                height: "min-content",
                maxWidth: "90vw",
                justifyContent: "center",
              }}
            >
              Register now to access all GridMarket content and functions
            </Typography>

            <Button
              variant="filled"
              sx={{
                ml: "auto",
                mr: "auto",
                backgroundColor: "#3aa4ff",
                color: "#FFF",
              }}
              onClick={() => {
                window.location.replace(
                  "https://platform.gridmarket.com/login"
                );
              }}
            >
              Login or register
            </Button>
          </Box>
        </Box>
      </div>
      {(subscribeStep === 1 || subscribeStep === 2) && (
        <Box>
          <Box
            sx={{
              backgroundColor: "#f3f7f7",
              position: "fixed",
              width: "40%",
              textAlign: "center",
              boxShadow: "-3px 5px 5px 5px #ccc",
              top: "25%",
              left: "50%",
              padding: 10,
              transform: "translate(-50%, 0)",
            }}
          >
            <Button
              size="large"
              sx={{
                textTransform: "none",
                position: "fixed",
                left: 0,
                top: 0,
              }}
              onClick={() => {
                setSubscribeStep(0);
                setSubscribeStyle("");
              }}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
            {subscribeStep === 1 && (
              <>
                <Typography mt={4}>
                  Subscribe for updates on this property.
                </Typography>

                <Grid container direction="column">
                  <FormControl margin="normal" required fullWidth>
                    <Grid item>
                      <TextField
                        name="fName"
                        type="fName"
                        id="fName"
                        label="First Name:"
                        autoComplete="given-name"
                        onChange={handleFormChange}
                        value={formState.name}
                        autoFocus
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item mt={2}>
                      <TextField
                        id="email"
                        name="email"
                        label="Email Address:"
                        autoComplete="email"
                        type="email"
                        onChange={handleFormChange}
                        value={formState.email}
                        required
                        fullWidth
                      />
                    </Grid>

                    <Grid item mt={2}>
                      <Button
                        disabled={submitDisabled}
                        onClick={subscribeSubmission}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </FormControl>
                </Grid>
              </>
            )}
            {subscribeStep === 2 && (
              <>
                <Typography variant="body2" sx={{ mt: 3 }}>
                  You are subscribed to updates for this property.
                </Typography>
                <Button onClick={subscribeSubmission} sx={{ mt: 8 }}>
                  Continue
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default React.memo(BuildingDetailsComponent);
