import React, { useCallback, useRef } from "react";
import {
  Box,
  Chip,
  Card,
  CardContent,
  MenuItem,
  Select,
  Stack,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { listingGallery as style } from "../../core/style";
import { useNavigate, useLocation } from "react-router-dom";
import translations from "../../core/translations";
import Spinner from "../building/loadingSpinner";
import { clusterData } from "../building/clusterData";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { titleCase } from "../../core/titleCase";
import { queryBuilder } from "../../core/queryBuilder";
import { IconsOnlyRecommendations } from "../building/buildingRecommendations";

function ListingComponent() {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = React.useState({
    featured: [],
    published: [],
  });
  const [listingQuantity, setListingQuantity] = React.useState(9);
  const [wordpressStyle, setWordpressStyle] = React.useState(false);
  const [pageOffset, setPageOffset] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [twoRows, setTwoRows] = React.useState(false);
  const [filters, setFilters] = React.useState({
    technology: [],
    financing: [],
    country: [],
  });
  const [icons, setIcons] = React.useState("old");
  const { pathname } = useLocation();

  const getPublishedData = useCallback(async (offset = null) => {
    let filtersQuery = queryBuilder(filters);
    // new query
    const airtableApiKey = process.env.REACT_APP_AIRTABLE_KEY;
    const gridmarketApiKey = process.env.REACT_APP_GRIDMARKET_KEY;
    const Airtable = require("airtable");
    const base = new Airtable({
      apiKey: airtableApiKey,
    }).base("appGItvPPik8JRDYv");

    base("Sites")
      .select({
        view: "Published",
      })
      .firstPage(function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {});
        setIsLoading(false);
        setData((previousVal) => ({
          ...previousVal,
          published: !offset ? records : [...previousVal.published, ...records],
        }));
      });

    // await base("Sites")
    //   .select({
    //     view: "Public Marketplace",
    //     ...(offset && { offset }),
    //     ...(filtersQuery && { filterByFormula: filtersQuery }),
    //   })
    //   .firstPage(function (err, records) {
    //     if (err) {
    //       console.error(err);
    //       return;
    //     }
    //     records.forEach(function (record) {});
    //     setIsLoading(false);

    //     setData((previousVal) => ({
    //       ...previousVal,
    //       published: !offset ? records : [...previousVal.published, ...records],
    //     }));
    //   });
    // old query

    // const res = await axios({
    //   method: "post",
    //   url: "/listRecords",
    //   data: {
    //     pageSize,
    //     view: "Published",
    //     ...(offset && { offset }),
    //     ...(filtersQuery && { filterByFormula: filtersQuery }),
    //   },
    // });

    // setPageOffset(res.data.offset || null);
  }, []);

  const checkIfWordpress = () => {
    if (pathname === "/gallery" || pathname === "/gallery2") {
      setWordpressStyle(true);
      setListingQuantity(16);
    }
    if (pathname === "/gallery2") {
      setTwoRows(true);
    }
  };

  React.useEffect(() => {
    getPublishedData();
    checkIfWordpress();
  }, [pathname]);

  React.useEffect(() => {}, [filters]);
  const iconWidth = 55;

  const seeMore = () => {
    setListingQuantity((prev) => prev + 9);
  };

  const updateFilters = useCallback((event) => {
    const filterData = {};
    filterData[event.label] = event.field;
    setFilters((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify({ ...prev, ...filterData })) {
        return { ...prev, ...filterData };
      }
      return prev;
    });
    setPageOffset(null);
  }, []);

  return !wordpressStyle ? (
    <Box sx={style.container}>
      <Stack sx={style.contentWrapper} spacing={3}>
        <Stack spacing={1} sx={{ width: "85vw", margin: "auto" }}>
          <Typography variant="h4" color={"primary"}>
            Explore Project Opportunities
          </Typography>
          <Typography textTransform={"uppercase"}>
            GridMarket organizes projects around the world
          </Typography>
          {/* <Box>
            <Button sx={{ width: 4, m: 1 }} onClick={() => setIcons("")}>
              old colors
            </Button>
            <Button sx={{ width: 4, m: 1 }} onClick={() => setIcons("Alt")}>
              alt colors
            </Button>
            <Button sx={{ width: 4, m: 1 }} onClick={() => setIcons("One")}>
              one color
            </Button>
            <Button sx={{ width: 4, m: 1 }} onClick={() => setIcons("Outline")}>
              outline
            </Button>
          </Box> */}
        </Stack>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box>
            <Gallery
              data={data.published}
              matchesMobile={matchesMobile}
              listingQuantity={listingQuantity}
              wordpressStyle={wordpressStyle}
              iconWidth={iconWidth}
              iconType={icons}
            />
          </Box>
        )}

        <Box sx={style.butttonWrapper}>
          <Button
            onClick={seeMore}
            variant="contained"
            size="medium"
            sx={style.button}
          >
            See More
            {/* See {listingQuantity === 8 ? "More" : "Less"} */}
          </Button>
        </Box>
      </Stack>
    </Box>
  ) : isLoading ? (
    <Spinner />
  ) : (
    <Box>
      <Gallery
        data={data.published}
        matchesMobile={matchesMobile}
        listingQuantity={listingQuantity}
        wordpressStyle={wordpressStyle}
        twoRows={twoRows}
        iconType={icons}
      />
      {!wordpressStyle && (
        <Box sx={style.butttonWrapper}>
          <Button
            onClick={seeMore}
            variant="contained"
            size="medium"
            sx={style.button}
          >
            See More
            {/* See {listingQuantity === 8 ? "More" : "Less"} */}
          </Button>
        </Box>
      )}
    </Box>
  );
}

function SelectField(props) {
  const [field, setField] = React.useState([]);
  const {
    data: { dropdown, label },
    updateFilters,
  } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setField(typeof value === "string" ? value.split(",") : value);
  };

  React.useEffect(() => {
    updateFilters({ label, field });
  }, [label, field, updateFilters]);

  return (
    <FormControl
      size="small"
      sx={{ ...style.filterWrapper, ...style.formControl }}
    >
      <InputLabel id={"label-" + props.data.id}>{titleCase(label)}</InputLabel>
      <Select
        labelId={"label-" + props.data.id}
        id={"select-" + props.data.id}
        multiple
        value={field}
        label={props.data.label}
        onChange={handleChange}
      >
        {Object.keys(dropdown).map((output, index) => (
          <MenuItem key={index} value={dropdown[output]}>
            {output}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function Gallery(props, isLoading, setIsLoading) {
  const {
    data,
    matchesMobile,
    listingQuantity,
    wordpressStyle,
    twoRows,
    iconType,
  } = props;
  const navigate = useNavigate();
  const [scrollAmount, setScrollAmount] = React.useState(300);

  const isWordpressCss = () => {
    const singleRow = {
      overflowX: "auto",
      overflowY: "hidden",
      scrollbarWidth: "none",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
    };
    const doubleRow = {
      display: "grid",
      gridTemplateRows: "auto auto",
      gridAutoFlow: "column",
      overflowX: "auto",
      scrollbarWidth: "none",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
    };
    if (twoRows) {
      return doubleRow;
    } else {
      return singleRow;
    }
  };
  // React.useEffect(() => {
  //   ref.current.scrollTo({
  //     top: 0,
  //     left: scrollAmount,
  //     behavior: "smooth",
  //   });
  // }, [scrollAmount]);

  const ref = useRef(null);
  const scroll = (scrollOffset) => {
    if (
      (scrollAmount > 0 || scrollOffset > 0) &&
      (scrollAmount < listingQuantity * 168 || scrollOffset < 0)
    ) {
      let newScroll = scrollAmount + scrollOffset;
      setScrollAmount(newScroll);
    }
  };

  const { pathname } = useLocation();
  const handleCardClick = (id) => {
    let url = `/building/${id}`;
    if (pathname === "/gallery" || pathname === "/gallery2") {
      const newWindow = window.open(url, "_blank");
      newWindow.thisid = `${id}`;
    } else {
      navigate(`/building/${id}`, {
        state: { id: id },
      });
    }
  };

  const twoDecimal = (num) => {
    return Math.round(num + Number.EPSILON).toLocaleString("en-US");
  };

  const assetValue = (value) => {
    if (value < 500000) {
      return "Under $500K";
    }
    if (value >= 500000 && value < 1000000) {
      return "$500K - $1M";
    }
    if (value >= 1000000 && value < 2000000) {
      return "$1M - $2M";
    }
    if (value >= 2000000 && value < 5000000) {
      return "$2M - $5M";
    }
    if (value >= 5000000) {
      return "Over $5M";
    }
  };

  return (
    <Box>
      {wordpressStyle && (
        <Box
          sx={{
            width: "20vw",
            m: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={() => scroll(-300)}>
            <KeyboardArrowLeft />
          </Button>
          <Button onClick={() => scroll(300)}>
            <KeyboardArrowRight />
          </Button>
        </Box>
      )}

      <Stack
        direction={"row"}
        sx={wordpressStyle ? isWordpressCss() : style.galleryWrapper}
        ref={ref}
        justifyContent={wordpressStyle ? null : "center"}
        className="hideScroll"
      >
        {data.slice(0, listingQuantity).map(({ fields: node, id }) => {
          const moreCount =
            clusterData[node["GM Property Type"]].additionalNeeds?.length - 2;
          return (
            <Card
              key={id}
              className={"card"}
              justifyContent="space-between"
              sx={{
                ...style.galleryContentWrapper,
                ...(matchesMobile && { width: "100%" }),
                scrollbarWidth: "none",
                margin: 2,
                borderRadius: "25px",
              }}
              raised
            >
              <CardContent
                spacing={1}
                onClick={() => handleCardClick(id)}
                sx={{
                  borderRadius: "25px",
                  // border: "1px red solid",
                  // pl: 2,
                  // pr: 2,
                }}
              >
                <Box>
                  <Box
                    component={"img"}
                    sx={style.galleryImage}
                    src={
                      node["Featured Image"]
                        ? node["Featured Image"][0].url
                        : ""
                    }
                  />
                  {/* <Grid
                    container
                    justifyContent="space-between"
                    direction="row"
                  > */}
                  <Typography variant="h6" fontWeight={600}>
                    {node["Building Type"]} in{" "}
                    {node["City"] + ", " + node["State"]}
                  </Typography>
                  {/* <Typography variant="h6" fontWeight={600}>
                      {node["City"] + ", " + node["State"]}
                    </Typography> */}
                  {/* </Grid> */}
                </Box>
                {/* <Grid container justifyContent="space-between" direction="row"> */}{" "}
                <Box>
                  <Typography variant="body2" fontWeight={"bold"} spacing={3}>
                    DER Asset Value
                  </Typography>
                  <Typography variant="body2">
                    {assetValue(node["Capex: Asset Value"])}
                  </Typography>
                  {/* </Grid> */}
                </Box>
                <Box>
                  <Typography variant="body2" fontWeight={"bold"}>
                    Recommended Solutions
                  </Typography>
                  <IconsOnlyRecommendations
                    pv={node["PV (kW)"]}
                    chp={node["CHP (kW)"]}
                    batteryKw={node["Battery (kW)"]}
                    batteryKwh={node["Battery (kWh)"]}
                    technologies={
                      clusterData[node["GM Property Type"]].technologies
                    }
                    iconType={iconType}
                    tooltip
                    solid
                  />
                </Box>
                <Box>
                  <Typography variant="body2" fontWeight={"bold"}>
                    Additional Needs
                  </Typography>
                  <Box
                    sx={{
                      transform: "translateX(-7px)",
                      mb: 1,
                    }}
                  >
                    {clusterData[node["GM Property Type"]].additionalNeeds
                      ?.slice(0, 2)
                      .map((need) => {
                        return (
                          <Chip
                            label={need}
                            key={need}
                            sx={{
                              backgroundColor: "#e7eeee",
                              color: "black",
                              width: "fit-content",
                              m: 0.2,
                            }}
                          />
                        );
                      })}

                    {moreCount > 0 && (
                      <Chip
                        label={`+${moreCount} more`}
                        key={"more"}
                        sx={{
                          backgroundColor: "#e7eeee",
                          color: "black",
                          width: "fit-content",
                          m: 0.2,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                {clusterData[node["GM Property Type"]]?.targets && (
                  <>
                    <Grid container direction="column">
                      <Typography variant="body2" fontWeight={"bold"}>
                        Targets
                      </Typography>
                      {Object.keys(
                        clusterData[node["GM Property Type"]].targets
                      ).map((target) => {
                        return (
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                            key={
                              clusterData[node["GM Property Type"]].targets[
                                target
                              ].label
                            }
                          >
                            <Typography variant="body2">
                              {/* {node["CO2: 1st year Reduction (kg)"]} */}
                              {(clusterData[node["GM Property Type"]].targets[
                                target
                              ].label === "Resilience" &&
                                clusterData[node["GM Property Type"]].targets[
                                  target
                                ].value) ||
                              clusterData[node["GM Property Type"]].targets[
                                target
                              ].label != "Resilience"
                                ? clusterData[node["GM Property Type"]].targets[
                                    target
                                  ].label
                                : null}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "bold" }}
                            >
                              {clusterData[node["GM Property Type"]].targets[
                                target
                              ].label === "Resilience" &&
                              clusterData[node["GM Property Type"]].targets[
                                target
                              ].value ? (
                                <CheckCircleOutlineIcon
                                  sx={{
                                    mb: -1,
                                    mr: 3,
                                  }}
                                />
                              ) : (
                                clusterData[node["GM Property Type"]].targets[
                                  target
                                ].value
                              )}
                            </Typography>
                          </Grid>
                        );
                      })}
                      <Grid
                        container
                        justifyContent="space-between"
                        direction="row"
                        key={"co2Reduction"}
                        sx={{ mb: 2 }}
                      >
                        <Typography variant="body2">CO2 Reduction</Typography>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          ~ {twoDecimal(node["CO2: 1st year Reduction (kg)"])}{" "}
                          kg/yr
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          );
        })}
      </Stack>
    </Box>
  );
}

export default React.memo(ListingComponent);
