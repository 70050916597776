import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import RecommendationIcon from "./recommendationIcon";

const BuildingRecommendation = ({
  children,
  description,
  iconProps,
  value,
  valueProps = {},
}) =>
  value != "Not Ideal for Site" && (
    <Grid
      container
      direction="column"
      className="recommendations-item"
      alignItems="center"
      item
      xs={12}
      sm={4}
      md={3}
      lg={2.4}
      mt={3}
    >
      <Grid xs={2}>
        <Box width={50} margin={"auto"}>
          <RecommendationIcon {...iconProps} />
        </Box>
      </Grid>
      <Grid xs={2} display={"flex"} item>
        <label
          className="headerText"
          sx={{ maxWidth: 120 }}
          title={description}
        >
          {iconProps.label.slice(0, iconProps.label.length - 1)}
        </label>
      </Grid>
      <Grid xs={2} item>
        <Typography
          variant="body2"
          sx={{ minWidth: 320, textAlign: "center" }}
          {...valueProps}
        >
          {value ? value : <Box sx={{ visibility: "hidden" }}>-</Box>}
        </Typography>
      </Grid>

      {children}
    </Grid>
  );

export default function BuildingRecommendations({
  chp,
  estimates,
  fuel_cell,
  solar,
  valueProps,
  tooltip,
  solid,
  technologies,
}) {
  return (
    <Grid
      className="recommendations"
      container
      padding={1}
      sx={{ mt: 5, width: "95%", m: "auto" }}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <BuildingRecommendation
        iconProps={{
          type: "storage",
          value: estimates.storage,
          label: "Storage Estimate:",
          tooltip,
          solid,
        }}
        value={estimates.storage}
        valueProps={valueProps}
      />
      <BuildingRecommendation
        iconProps={{
          type: "fuel_cell",
          value: fuel_cell,
          label: "Fuel Cell Estimate:",
          tooltip,
          solid,
        }}
        valueProps={valueProps}
        label="Fuel Cell Estimate"
        value={estimates.fuel_cell}
      />
      <BuildingRecommendation
        iconProps={{
          type: "chp",
          value: chp,
          label: "CHP:",
          tooltip,
          solid,
        }}
        valueProps={valueProps}
        label="CHP Estimate"
        value={estimates.chp}
      />
      <BuildingRecommendation
        iconProps={{
          type: "solar",
          value: solar,
          label: "Solar Estimate:",
          tooltip,
          solid,
        }}
        valueProps={valueProps}
        label="Solar Estimate"
        value={estimates.solar}
      />
      {technologies.includes("Electrification & Efficiency") && (
        <BuildingRecommendation
          iconProps={{
            type: "efficiency",
            value: 0,
            label: "Electrification & Efficiency:",
            solid,
          }}
          valueProps={valueProps}
          label="Electrification & Efficiency"
          value={""}
        />
      )}
      {technologies.includes("Microgrid Capability") && (
        <BuildingRecommendation
          iconProps={{
            type: "microgrid",
            label: "Microgrid Capability:",
            value: null,
            solid,
          }}
          valueProps={valueProps}
          label="Microgrid Capability"
          value={null}
        />
      )}
      {technologies.includes("IOT & Controls") && (
        <BuildingRecommendation
          iconProps={{
            type: "IOT & Controls",
            value: null,
            label: "IOT & Controls:",
            solid,
          }}
          valueProps={valueProps}
          label="IOT & Controls"
          value={null}
        />
      )}
      {technologies.includes("EV Charging & Green Fleets") && (
        <BuildingRecommendation
          iconProps={{
            type: "evcharging",
            value: null,
            label: "EV Charging & Green Fleets:",
            solid,
          }}
          valueProps={valueProps}
          label="EV Charging & Green Fleets"
          value={null}
        />
      )}
      {technologies.includes("VPPA, RECs, & Offsite Solutions") && (
        <BuildingRecommendation
          iconProps={{
            type: "vppa",
            label: "VPPAs, RECs, & Offsite Energy:",
            value: null,
            solid,
          }}
          valueProps={valueProps}
          label="VPPAs, RECs, & Offsite Energy"
          value={null}
        />
      )}
      {technologies.includes("Wind") && (
        <BuildingRecommendation
          iconProps={{
            type: "wind",
            label: "Wind:",
            value: null,
            solid,
          }}
          valueProps={valueProps}
          label="Wind Energy"
          value={null}
        />
      )}
    </Grid>
  );
}
const IconOnlyRecommendation = ({ iconProps }) => (
  <>
    <Grid
      item
      sx={{
        width: 55,
        m: "3px",
      }}
    >
      <RecommendationIcon {...iconProps} />
    </Grid>
  </>
);

export function IconsOnlyRecommendations({
  chp,
  pv,
  batteryKw,
  batteryKwh,
  tooltip,
  solid,
  technologies,
  iconType,
}) {
  return (
    <Grid container direction="row">
      {!!pv && (
        <>
          <IconOnlyRecommendation
            iconProps={{
              type: "solar",
              value: pv,
              label: "Solar:",
              pv: { pv },
              tooltip,
              solid,
              iconType,
            }}
          />
        </>
      )}
      {!!chp && (
        <IconOnlyRecommendation
          iconProps={{
            type: "chp",
            value: chp,
            label: "CHP:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {!!batteryKw && !!batteryKwh && (
        <IconOnlyRecommendation
          iconProps={{
            type: "storage",
            value: { batteryKw: batteryKw, batteryKwH: batteryKwh },
            label: "Storage:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("Microgrid") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "microgrid",
            label: "Microgrid:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("IOT & Controls") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "IOT & Controls",
            label: "IOT & Controls:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("Microgrid Capability") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "microgrid",
            label: "Microgrid Capability:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("VPPA, RECs, & Offsite Solutions") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "vppa",
            label: "VPPA, RECs, & Offsite Solutions:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("Wind") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "wind",
            label: "Wind:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("Electrification & Efficiency") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "efficiency",
            label: "Electrification & Efficiency:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
      {technologies?.includes("EV Charging & Green Fleets") && (
        <IconOnlyRecommendation
          iconProps={{
            type: "evcharging",
            label: "EV Charging & Green Fleets:",
            tooltip,
            solid,
            iconType,
          }}
        />
      )}
    </Grid>
  );
}
