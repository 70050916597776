import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ScrollToTop from "./core/scrollToTop";
import { LightTheme } from "./core/light-theme";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import HomePage from "./pages/home";
import ListingProfilePage from "./pages/listingProfile";
import ListingComponent from "./components/listingGallery/listingGallery";
import OnboardingComponent from "./components/onboarding/onboarding";

function App() {
  return (
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <React.Suspense fallback={<CircularProgress />}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path={""} element={<Navigate to="home" replace />} />
            <Route path={"/home"} element={<HomePage />}></Route>
            <Route
              path={"/building/:id"}
              element={<ListingProfilePage />}
            ></Route>
            <Route path={"/gallery"} element={<ListingComponent />}></Route>
            <Route path={"/gallery2"} element={<ListingComponent />}></Route>
            <Route path={"/savings"} element={<OnboardingComponent />}></Route>
          </Routes>
        </Router>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default React.memo(App);
