import React from "react";
import "./loadingSpinner.sass";

const Spinner = (props) => {
  return (
    <div className="loader-container" {...props}>
      <svg className="loader" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export default Spinner;
