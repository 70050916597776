let E2E_API_URL = `https://api.gridmarket.xyz/`;
let PROSPECTING_API_URL = `https://platform.gridmarket.com/prospecting-api/`;
let MARTIN_API_URL = `https://martin.gridmarket.xyz/`;
let TILES_API_URL = "https://tiles.gridmarket.xyz/";
let ENVIRONMENT = "production";

if (window.location.hostname === "dev.gridmarket.xyz") {
  E2E_API_URL = `https://api.gridmarket.xyz/`;
  PROSPECTING_API_URL = `https://platform.gridmarket.com/prospecting-api/`;
  ENVIRONMENT = "development";
}

if (window.location.hostname === "dev.gridmarket.net") {
  E2E_API_URL = `https://staging.gridmarket.com/e2e-api/`;
  PROSPECTING_API_URL = `https://staging.gridmarket.com/prospecting-api/`;
  MARTIN_API_URL = `https://staging.gridmarket.com/martin/`;
  TILES_API_URL = "https://staging.gridmarket.com/tiles/";
  ENVIRONMENT = "staging";
}

if (window.location.hostname === "platform.gridmarket.xyz") {
  E2E_API_URL = `https://platform.gridmarket.xyz/e2e-api/`;
  PROSPECTING_API_URL = `https://platform.gridmarket.xyz/prospecting-api/`;
  MARTIN_API_URL = `https://platform.gridmarket.xyz/martin/`;
  TILES_API_URL = "https://platform.gridmarket.xyz/tiles/";
  ENVIRONMENT = "development";
}

if (
  window.location.hostname === "platform.gridmarket.com" ||
  window.location.hostname === "live.gridmarket.net"
) {
  E2E_API_URL = `https://platform.gridmarket.com/e2e-api/`;
  PROSPECTING_API_URL = `https://platform.gridmarket.com/prospecting-api/`;
  MARTIN_API_URL = `https://platform.gridmarket.com/martin/`;
  TILES_API_URL = "https://platform.gridmarket.com/tiles/";
  ENVIRONMENT = "production";
}

if (
  window.location.hostname === "staging.gridmarket.com" ||
  window.location.hostname === "localhost"
) {
  E2E_API_URL = `https://staging.gridmarket.com/e2e-api/`;
  PROSPECTING_API_URL = `https://platform.gridmarket.com/prospecting-api/`;
  MARTIN_API_URL = `https://staging.gridmarket.com/martin/`;
  TILES_API_URL = "https://staging.gridmarket.com/tiles/";
  ENVIRONMENT = "staging";
}

export default {
  E2E_API_URL,
  PROSPECTING_API_URL,
  MARTIN_API_URL,
  TILES_API_URL,
  ENVIRONMENT,
};
