import { createTheme } from "@mui/material/styles";

import InterRoman from "./assets/fonts/Inter-roman.var.woff2";
import InterItalic from "./assets/fonts/Inter-italic.var.woff2";

const interRoman = {
  fontFamily: "Inter var",
  fontWeight: "100 900",
  fontDisplay: "swap",
  fontStyle: "normal",
  fontNamedInstance: "Regular",
  src: `url(${InterRoman}) format("woff2")`,
};

const interItalic = {
  fontFamily: "Inter var",
  fontWeight: "100 900",
  fontDisplay: "swap",
  fontStyle: "italic",
  fontNamedInstance: "Italic",
  src: `url(${InterItalic}) format("woff2")`,
};

export default createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h4: {
      fontWeight: 500,
      letterSpacing: "-1px",
    },
  },
  palette: {
    background: {
      default: "#eee",
    },
    primary: {
      main: "#0070FF",
    },
    secondary: {
      main: "#f50057",
    },
    success: {
      main: "#c5e1a5",
      light: "#dcedc8",
      dark: "#aed581",
      contrastText: "#8bc34a",
    },
    text: {
      primary: "#404040",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "#D7D7D7",
    },
    warning: {
      main: "#ffe082",
      light: "#ffecb3",
      dark: "#ffd54f",
      contrastText: "#ffb300",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [interRoman, interItalic],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        inset: {
          marginLeft: 16,
        },
      },
    },
    MuiListItem: {
      button: {
        styleOverrides: {
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0)",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        overline: {
          fontSize: "0.65rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.625rem",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 5,
          "& svg": {
            maxHeight: 20,
            maxWidth: 20,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#01a3ff",
          height: 1,
        },
        rail: {
          height: 1,
        },
        track: {
          height: 1,
        },
        thumb: {
          width: 12,
          height: 12,
          background: "#01a3ff",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "0.8rem",
          borderBottom: "1px solid #DFDFDF",
        },
        sticky: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
  },
});
