import axios from "axios";
import qs from "qs";
import e2eInstance from "./axios";
import conf from "../conf";

const instance = axios.create({
  baseURL: conf.PROSPECTING_API_URL,
  timeout: 30000,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
});

const upload = (url, filesLike, onProgress) => {
  let formData = null;
  if (Array.isArray(filesLike)) {
    formData = new FormData();
    filesLike.forEach((file) => formData.append("file[]", file));
  }

  if (filesLike instanceof FormData) formData = filesLike;
  if (!formData) throw new Error(`Files must be either FormData or array`);

  return e2eInstance.post(url, formData, {
    timeout: Infinity,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => {
      const percentage = (event.loaded / event.total) * 100;
      return onProgress(percentage, event);
    },
  });
};

const Dataset = {
  getFeature: (datasetId, featureId) =>
    e2eInstance.get(`/datasets/${datasetId}/feature/${featureId}`, {
      timeout: 100000,
    }),
  getDataset: (datasetId) => e2eInstance.get(`/datasets/${datasetId}`),
  getFeatures: (datasetId, layerId) =>
    e2eInstance.get(`/datasets/${datasetId}/features`, {
      params: {
        layer_id: layerId,
      },
    }),
  getLayers: (datasetId) => e2eInstance.get(`/datasets/${datasetId}/layers`),
  createLayer: (datasetId, data) =>
    e2eInstance.post(`/datasets/${datasetId}/layer`, data),
  updateLayer: (datasetId, layerId, params) =>
    e2eInstance.patch(`/datasets/${datasetId}/layer/${layerId}`, params),
  updateFeature: (datasetId, feature) =>
    e2eInstance.patch(`/datasets/${datasetId}/${feature.id}`, feature),
  get: (groupId) =>
    e2eInstance.get(`/datasets`, {
      params: {
        group_id: groupId,
      },
    }),
  getLayer: (datasetId, layerId) =>
    e2eInstance.get(`/datasets/${datasetId}/layer/${layerId}`),
  createFeature: (datasetId, feature) =>
    e2eInstance.post(`/datasets/${datasetId}/${feature.id}`, feature),
  updateFeatureProperties: (datasetId, featureId, properties) =>
    e2eInstance.post(
      `/datasets/${datasetId}/${featureId}/properties`,
      properties
    ),
  deleteFeature: (datasetId, featureId) =>
    e2eInstance.delete(`/datasets/${datasetId}/${featureId}`),
  createDataset: (data) => e2eInstance.put("/datasets", data),
  updateDataset: (datasetId, params) =>
    e2eInstance.patch(`/datasets/${datasetId}`, params),
  deleteDataset: (datasetId) => e2eInstance.delete(`/datasets/${datasetId}`),
  deleteLayer: (datasetId, layerId) =>
    e2eInstance.delete(`/datasets/${datasetId}/layer/${layerId}`),
  upload: (formData, params) =>
    e2eInstance.post("datasets/upload", formData, params),
  uploadDataset: (
    files,
    { name, description, projectId, onUploadProgress }
  ) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("projectId", projectId);
    files.forEach((file) => {
      formData.append("file", file);
    });

    return e2eInstance.post("datasets/upload", formData, {
      timeout: Infinity,
      headers: {
        "Content-Type": "multipart/form-data",
        "Cache-Control": "no-cache, no-store",
      },
      onUploadProgress,
    });
  },
  removeDatasetProject: (datasetId, projectId) =>
    e2eInstance.delete(`/datasets/${datasetId}/project/${projectId}`),
  addDatasetProject: (datasetId, projectId) =>
    e2eInstance.get(`/datasets/${datasetId}/project/${projectId}`),
  search: (term, groupId) =>
    e2eInstance.get("datasets/search", {
      params: {
        term,
        group_id: groupId,
      },
    }),
};

const Building = {
  addFiles: (buildingId, fileIds) =>
    e2eInstance.patch(`/building/${buildingId}/files/${fileIds.join(";")}`),
  getBuildings: (params) =>
    instance.post("/building", {
      ...params,
    }),
  getBuildingsById: (ids, limit = 30) =>
    instance.get("/building", {
      params: {
        ids,
        limit,
      },
    }),
  getBuilding: (buildingId, projectId) =>
    instance.get(
      `/building/${buildingId}`,
      projectId
        ? {
            project_id: projectId,
          }
        : {}
    ),
  getPlaces: (lon, lat) => instance.get(`/places`, { params: { lon, lat } }),
  getPowerOptions: (lon, lat) =>
    instance.get(`/powerline`, { params: { lon, lat } }),
  getWeather: (lon, lat) => instance.get(`/weather`, { params: { lon, lat } }),
  getPredictions: (buildingId) =>
    instance.get(`/building/${buildingId}/predictions?period=7D`),
  getHoverInfo: (longitude, latitude) =>
    e2eInstance.get(`/points/hoverinfo`, { params: { longitude, latitude } }),
};

const Project = {
  getProject: (projectId) => e2eInstance.get(`/project/${projectId}`),
  getProjectTerms: (projectId) =>
    e2eInstance.get(`/project/${projectId}/terms`),
  getStatus: (projectId) => e2eInstance.get(`/project/${projectId}/status`),
  createProjectProposal: (projectId, files, questions, signature) =>
    e2eInstance.post(`/project/${projectId}/proposals`, {
      files,
      questions,
      signature,
    }),
  getProjectFinancials: (projectId) =>
    e2eInstance.get(`/project/${projectId}/financials`),
  getProjectRFPQuestions: (projectId) =>
    e2eInstance.get(`/project/${projectId}/rfp_questions`),
  setProjectRFPQuestions: (projectId, questions) =>
    e2eInstance.patch(`/project/${projectId}/rfp_questions`, questions),
  createProjectRFPQuestion: (projectId, data) =>
    e2eInstance.post(`/project/${projectId}/rfp_question`, data),
  deleteProjectRFPQuestion: (projectId, questionId) =>
    e2eInstance.delete(`/project/${projectId}/rfp_question/${questionId}`),
  updateProjectRFPQuestion: (projectId, questionId, data) =>
    e2eInstance.patch(`/project/${projectId}/rfp_question/${questionId}`, data),
  updateProjectTerms: (projectId, termsId, data) =>
    e2eInstance.patch(`/project/${projectId}/terms/${termsId}`, data),
  createProjectTerms: (projectId, terms) =>
    e2eInstance.post(`/project/${projectId}/terms`, terms),
  deleteProjectTerms: (projectId, termsId) =>
    e2eInstance.delete(`/project/${projectId}/terms/${termsId}`),
  getProjectTermsById: (projectId, termsId) =>
    e2eInstance.get(`/project/${projectId}/terms/${termsId}`),
  hasAccess: (projectId) => e2eInstance.get(`/project/${projectId}/has_access`),
  updateProject: (projectId, data) =>
    e2eInstance.patch(`/project/${projectId}`, data),
  getProjects: () => e2eInstance.get(`/project`),
  deleteProject: (projectId) => e2eInstance.delete(`/project/${projectId}`),
  getProjectBuilding: (projectId, buildingId) =>
    e2eInstance.get(`/project/${projectId}/building/${buildingId}`),
  getProjectFeature: (projectId, featureId) =>
    e2eInstance.get(`/project/${projectId}/feature/${featureId}`),
  refreshProjectFinancials: (projectId) =>
    e2eInstance.get(`/project/${projectId}/financials/refresh`),
  updateProjectBuilding: (projectId, buildingId, buildingData) =>
    e2eInstance.post(
      `/project/${projectId}/building/${buildingId}`,
      buildingData
    ),
  getProjectVendors: (projectId) =>
    e2eInstance.get(`/project/${projectId}/vendors`),
  getProjectBuildings: (projectId) =>
    e2eInstance.get(`/project/${projectId}/buildings`),
  getProjectLayers: (projectId) =>
    e2eInstance.get(`/project/${projectId}/layers`),
  getBuildingFinancials: (projectId, buildingId) =>
    e2eInstance.get(`/project/${projectId}/building/${buildingId}/financials`),
  updateBuildingSettings: (projectId, buildingId, settings) =>
    e2eInstance.patch(
      `/project/${projectId}/building/${buildingId}/settings`,
      settings
    ),
  createBuilding: (projectId, buildingData) =>
    e2eInstance.post(`/project/${projectId}/add`, buildingData),

  addBuilding: (projectId, buildingIds) =>
    e2eInstance.post(`/project/${projectId}/add`, buildingIds),
  addBuildingByImportId: (projectId, importId) =>
    e2eInstance.post(`/project/${projectId}/add`, {
      import_id: importId,
    }),
  createProjectByImportId: (importId, projectName = "Imported Project") =>
    e2eInstance.post("/project", {
      name: projectName,
      importId,
    }),
  removeBuilding: (projectId, buildingIds) =>
    e2eInstance.post(`/project/${projectId}/remove`, buildingIds),
  createProject: (params) => e2eInstance.post("/project", params),
  updateProjectBuildingGeometry: (projectId, buildingId, geometry) =>
    e2eInstance.post(
      `/project/${projectId}/building/${buildingId}/geometry`,
      geometry
    ),
  acceptInfoMessage: (projectId) =>
    e2eInstance.post(`/project/${projectId}/accept_infomessage`),
  setProjectSettings: (projectId, settingsData) =>
    e2eInstance.patch(`/project/${projectId}/settings`, settingsData),
  getSystems: (projectId) =>
    e2eInstance.get("/crawler/system", { params: { project_id: projectId } }),
  getSystemData: (systemId, timeWindow, time) =>
    e2eInstance.get(`/crawler/system/${systemId}/data`, {
      params: { timeWindow, time },
    }),
  getFiles: (projectId, fileIds = []) =>
    e2eInstance.get(`/project/${projectId}/files/${fileIds.join(";")}`),
  deleteFiles: (projectId, fileIds) =>
    e2eInstance.delete(`/project/${projectId}/files/${fileIds.join(";")}`),
  addFiles: (projectId, fileIds) =>
    e2eInstance.patch(`/project/${projectId}/files/${fileIds.join(";")}`),
  getProposal: (projectId, proposalId) =>
    e2eInstance.get(`/project/${projectId}/proposals/${proposalId}`),
  deleteProposal: (projectId, proposalId) =>
    e2eInstance.delete(`/project/${projectId}/proposals/${proposalId}`),
  getProposals: (projectId) =>
    e2eInstance.get(`/project/${projectId}/proposals`),
  addProposals: (projectId, fileIds) =>
    e2eInstance.patch(`/project/${projectId}/proposals/${fileIds.join(";")}`),
  addVendors: (projectId, vendorIds) =>
    e2eInstance.post(`/project/${projectId}/share`, vendorIds),
  addMembers: (projectId, memberIds) =>
    e2eInstance.put(`/project/${projectId}/members`, memberIds),
  getChat: (projectId) => e2eInstance.get(`/project/${projectId}/chat`),
  updateChat: (projectId, chatData) =>
    e2eInstance.post(`/project/${projectId}/chat`, {
      chat: chatData,
    }),
  uploadBuildingLoadProfile: (
    projectId,
    buildingId,
    files,
    onProgressCallback
  ) => {
    const url = `/project/${projectId}/building/${buildingId}/load_profile`;
    return upload(url, files, onProgressCallback);
  },
  deleteBuildingLoadProfile: (projectId, buildingId) =>
    e2eInstance.delete(
      `/project/${projectId}/building/${buildingId}/load_profile`
    ),
  getVendorSuggestions: (projectId, q) =>
    e2eInstance.get(`/project/${projectId}/vendor_suggestions`, {
      params: { q },
    }),
  addVendor: (projectId, groupIdLike) =>
    e2eInstance.post(`/project/${projectId}/invite_vendor`, {
      id: groupIdLike,
    }),
  removeVendor: (projectId, groupId) =>
    e2eInstance.delete(`/project/${projectId}/remove_vendor`, {
      params: {
        group_id: groupId,
      },
    }),
  uploadBuildingCSV: (projectId, files, onProgressCallback) => {
    const url = `/importer-service/upload`;
    return upload(url, files, onProgressCallback);
  },
  checkForImport: (importId, importItemIndex) => {
    const url = `/importer-service/import/${importId}/check`;
    return e2eInstance.get(url, {
      params: {
        index: importItemIndex,
      },
    });
  },
  uncheckForImport: (importId, importItemIndex) => {
    const url = `/importer-service/import/${importId}/uncheck`;
    return e2eInstance.get(url, {
      params: {
        index: importItemIndex,
      },
    });
  },
  getImportStatus: (importId) => {
    const url = `/importer-service/status/${importId}`;
    return e2eInstance.get(url);
  },
};

const Group = {
  getSettings: (groupId) => e2eInstance.get(`/group/${groupId}/settings`),
  update: (groupId, data) => e2eInstance.patch(`/group/${groupId}`, data),
  setSettings: (groupId, data) =>
    e2eInstance.post(`/group/${groupId}/settings`, data),
  delete: (groupId) => e2eInstance.delete(`/group/${groupId}`),
  search: (query) =>
    e2eInstance.get(`/group/search`, {
      params: {
        query,
      },
    }),
};

const User = {
  validateResetToken: (token) => e2eInstance.get(`/reset/check/${token}`),
  validateUserActivationToken: (token) => e2eInstance.get(`/activate/${token}`),
  getGroups: () => e2eInstance.get(`/user/groups`),
  sendPasswordResetEmail: (email) =>
    e2eInstance.get(`/reset`, {
      params: { email },
    }),
  signup: (data) => e2eInstance.post(`/signup`, data),
  logout: () => e2eInstance.get(`/logout`),
  sendFeedback: (formData) => e2eInstance.post(`/user/feedback`, formData),
  onboardingComplete: () => e2eInstance.post(`/user/onboarding/complete`),
  submitOnboardingAnswer: (question, answer) =>
    e2eInstance.post(`/user/onboarding/answer`, {
      question,
      answer,
    }),
};

const Task = {
  get: (id) => e2eInstance.get(`/task/${id}`),
  create: (params) => e2eInstance.post("/task", params),
  update: (id, params) => e2eInstance.patch(`/task/${id}`, params),
  delete: (id) => e2eInstance.delete(`/task/${id}`),
  getProjectTasks: (projectId, buildingId) =>
    buildingId
      ? e2eInstance.get(`/project/${projectId}/building/${buildingId}/tasks`)
      : e2eInstance.get(`/project/${projectId}/tasks`),
};

const Data = {
  getDataset: (name) => e2eInstance.get(`/data/${name}`),
  getCountries: () => e2eInstance.get(`/data/countries`),
  getISOTerritories: () => e2eInstance.get(`/data/iso_territories`),
};

const SavedSearchFilters = {
  get: (id) => e2eInstance.get(`/user/search/${id}`),
  getAll: ({ projectId }) =>
    e2eInstance.get(`/user/search`, {
      params: {
        project_id: projectId,
      },
    }),
  create: (name, data, projectId) =>
    e2eInstance.post(`/user/search`, {
      name,
      data,
      project_id: projectId,
    }),
  delete: (id) => e2eInstance.delete(`/user/search/${id}`),
};

const Log = {
  get: (groupId, projectId) =>
    e2eInstance.get(`/log`, {
      params: {
        group_id: groupId,
        project_id: projectId,
      },
    }),
  getCSV: (groupId) =>
    e2eInstance.get(`/log/csv`, {
      params: {
        group_id: groupId,
      },
    }),
  getItem: (id) => e2eInstance.get(`/log/${id}`),
};

const Survey = {
  addSurvey: (projectId, data) =>
    e2eInstance.post(`/project/${projectId}/survey`, data),
};

const Workspace = {
  createFolder: (folderId, folderName) =>
    e2eInstance.post(`/workspaces/folders/${folderId}`, {
      name: folderName,
    }),
  move: (pathId, newParentId) =>
    e2eInstance.patch(`/workspaces/files/${pathId}`, {
      parentId: newParentId,
    }),
  delete: (itemIds) =>
    e2eInstance.delete(`/workspaces/folders`, {
      data: {
        ids: itemIds,
      },
    }),
  renameFolder: (folderId, newName) =>
    e2eInstance.patch(`/workspaces/folders/${folderId}`, {
      name: newName,
    }),
  getArchiveUrl: (parentId, fileIds) => {
    return e2eInstance.get(`/workspaces/folders/${parentId}/download`, {
      params: {
        file_ids: fileIds.join(";"),
      },
    });
  },
  createFile: (parentId, formData, params) =>
    e2eInstance.post(`/workspaces/${parentId}/files`, formData, params),
  getFile: (fileId) => e2eInstance.get(`/workspaces/files/${fileId}`),
  get: (id, sortKey) =>
    e2eInstance.get(`/workspaces/folders/${id}`, {
      params: {
        sort: sortKey,
      },
    }),
  upload: (parentId, filesLike, onProgress) => {
    let formData = null;
    if (Array.isArray(filesLike)) {
      formData = new FormData();
      filesLike.forEach((file) => formData.append("file[]", file));
    }

    if (filesLike instanceof FormData) formData = filesLike;
    if (!formData) throw new Error(`Files must be either FormData or array`);

    return e2eInstance.post(`/workspaces/${parentId}/files`, formData, {
      timeout: Infinity,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        const percentage = (event.loaded / event.total) * 100;
        return onProgress(percentage, event);
      },
    });
  },
};

const Address = {
  geocode: (address) =>
    e2eInstance.get(`/api/geocode`, {
      params: {
        address,
        key: "HwphuLg1MaToFC13M49QMG",
      },
    }),
  autocomplete: (address) =>
    e2eInstance.get(`/api/autocomplete`, {
      params: {
        q: address,
        key: "HwphuLg1MaToFC13M49QMG",
      },
    }),
};

export default {
  Address,
  Dataset,
  SavedSearchFilters,
  Data,
  Building,
  Project,
  Group,
  User,
  Task,
  Log,
  Survey,
  Workspace,
};
