const labels = {
  resiliency: "Resilience",
  rateReduction: "Rate Reduction",
  // billOffset: "Bill Offset",
  billOffset: "Savings",
  co2Reduction: "CO2 Reduction",
};

const tech = {
  ev: "EV Charging & Green Fleets",
  mc: "Microgrid Capability",
  ic: "IOT & Controls",
  ee: "Electrification & Efficiency",
  vppa: "VPPA, RECs, & Offsite Solutions",
};

const rateValues = [
  //0
  "0% - 5%",
  //1
  "15% - 20%",
  //2
  "13% - 18%",
  //3
  "20% - 30%",
  //4
  "19% - 24%",
  //5
  "10% - 15%",
  //6
  "12% - 17%",
  //7
  "19% - 28%",
  //8
  "18% - 29%",
  //9
  "12% - 35%",
  //10
  "3% - 11%",
  //11
  "11% - 19%",
  //12
  "15% - 42%",
];

const offsetValues = [
  //0
  "10% - 15%",
  //1
  "15% - 20%",
  //2
  "12% - 17%",
  //3
  "25% - 38%",
  //4
  "28% - 42%",
  //5
  "12% - 18%",
  //6
  "20% - 45%",
  //7
  "21% - 48%",
  //8
  "12% - 17%",
  //9
  "5% - 12%",
  //10
  "8% - 16%",
  //11
  "12% - 26%",
  //12
  "30% - 39%",
];

const co2Values = [
  //0
  "15% - 20%",
  //1
  "20% - 25%",
  //2
  "18% - 24%",
  //3
  "30% - 36%",
  //4
  "32% - 40%",
  //5
  "14% - 20%",
  //6
  "22% - 48%",
  //7
  "23% - 40%",
  //8
  "10% - 15%",
  //9
  "3% - 13%",
  //10
  "5% - 21%",
  //11
  "13% - 28%",
  //12
  "31% - 43%",
];

const co2ReductionValues = [
  //0
  "~38 kg/yr",
  //1
  "~138 kg/yr",
  //2
  "~238 kg/yr",
  //3
  "~338 kg/yr",
  //4
  "~438 kg/yr",
  //5
  "~538 kg/yr",
  //6
  "~638 kg/yr",
  //7
  "~738 kg/yr",
  //8
  "~838 kg/yr",
  //9
  "~938 kg/yr",
  //10
  "~1038 kg/yr",
  //11
  "~1138 kg/yr",
  //12
  "~1238 kg/yr",
];

const needs = {
  finPpa: "Financing (PPA)",
  finEsa: "Financing (ESA)",
  finLease: "Financing (Leasing)",
  om: "Operations & Management",
  ei: "EPC/Installer",
  eq: "Equipment",
  im: "Incentive Management",
  ds: "Development Services",
  la: "Legal Advisory",
  cc: "Carbon Credits",
  ta: "Tax Advisory",
  tipp: "Turnkey Independent Power Producer",
  epc: "Engineering, Procurement, & Construction",
  aa: "Accounting Advisory",
  er: "Environmental Reporting",
  pdw: "Performance Data Warehousing",
  mv: "Measurement & Verification",
  rs: "REC Settlement",
  vptsa: "VPPA Performance Tracking & Settlement Auditing",
};

export const clusterData = [
  // 0
  {
    technologies: [tech.mc, tech.ic, tech.vppa],
    targets: {
      resiliency: {
        label: labels.resiliency,
        value: true,
      },
      // rateReduction: { label: labels.rateReduction, value: rateValues[0] },
      billOffset: { label: labels.billOffset, value: offsetValues[0] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[0],
      // },
    },
    additionalNeeds: [needs.aa, needs.vptsa],
  },
  // 1
  {
    technologies: [tech.ev, tech.ic, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: false },
      // rateReduction: { label: labels.rateReduction, value: rateValues[1] },
      billOffset: { label: labels.billOffset, value: offsetValues[1] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[1],
      // },
    },
    additionalNeeds: [needs.finLease, needs.ta, needs.pdw],
  },
  //2
  {
    technologies: [tech.ev, tech.mc, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: false },
      // rateReduction: { label: labels.rateReduction, value: rateValues[2] },
      billOffset: { label: labels.billOffset, value: offsetValues[2] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[2],
      // },
    },
    additionalNeeds: [needs.finLease, needs.ds, needs.tipp, needs.mv],
  },
  //3
  {
    technologies: [tech.ev, tech.ic],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[3] },
      billOffset: { label: labels.billOffset, value: offsetValues[3] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[3],
      // },
    },
    additionalNeeds: [needs.finEsa, needs.im, needs.mv],
  },
  //4
  {
    technologies: [tech.ev, tech.mc],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[4] },
      billOffset: { label: labels.billOffset, value: offsetValues[4] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[4],
      // },
    },
    additionalNeeds: [needs.finPpa, needs.ei, needs.epc, needs.er, needs.pdw],
  },
  //5
  {
    technologies: [tech.mc, tech.ic, tech.vppa],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[5] },
      billOffset: { label: labels.billOffset, value: offsetValues[5] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[5],
      // },
    },
    additionalNeeds: [needs.finPpa, needs.im, needs.la],
  },
  //6
  {
    technologies: [tech.mc, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[6] },
      billOffset: { label: labels.billOffset, value: offsetValues[6] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[6],
      // },
    },
    additionalNeeds: [needs.finLease, needs.ei, needs.epc, needs.er, needs.pdw],
  },
  //7
  {
    technologies: [tech.ev, tech.ic, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[7] },
      billOffset: { label: labels.billOffset, value: offsetValues[7] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[7],
      // },
    },
    additionalNeeds: [needs.finEsa, needs.eq, needs.cc, needs.rs],
  },
  //8
  {
    technologies: [tech.ev, tech.mc],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[8] },
      billOffset: { label: labels.billOffset, value: offsetValues[8] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[8],
      // },
    },
    additionalNeeds: [needs.ei, needs.la, needs.epc],
  },
  //9
  {
    technologies: [tech.ic, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[9] },
      billOffset: { label: labels.billOffset, value: offsetValues[9] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[9],
      // },
    },
    additionalNeeds: [needs.om, needs.ds, needs.er],
  },
  //10
  {
    technologies: [tech.ev, tech.ic, tech.vpps],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[10] },
      billOffset: { label: labels.billOffset, value: offsetValues[10] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[10],
      // },
    },
    additionalNeeds: [needs.eq, needs.cc, needs.pdw],
  },
  //11
  {
    technologies: [tech.mc, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: { label: labels.rateReduction, value: rateValues[11] },
      billOffset: { label: labels.billOffset, value: offsetValues[11] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[11],
      // },
    },
    additionalNeeds: [needs.finPpa, needs.om, needs.ta, needs.epc],
  },
  //12
  {
    technologies: [tech.ev, tech.ic, tech.ee],
    targets: {
      resiliency: { label: labels.resiliency, value: true },
      // rateReduction: {
      //   label: labels.rateReduction,
      //   value: rateValues[12],
      // },
      billOffset: { label: labels.billOffset, value: offsetValues[12] },
      // co2Reduction: {
      //   label: labels.co2Reduction,
      //   value: co2ReductionValues[12],
      // },
    },
    additionalNeeds: [needs.finEsa, needs.im, needs.mv],
  },
];
