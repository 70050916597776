import React from "react";
import { styled } from "@mui/material/styles";
import "./nav.sass";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
  Typography,
  Collapse,
  List,
  ListItemButton,
  Popper,
  ListItemText,
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import gridmarket from "../../assets/gridmarket.svg";
import { navStyle as style } from "../../core/style";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
function NavComponent() {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [anchorElSolutions, setAnchorElSolutions] = React.useState(null);
  const openSolutions = Boolean(anchorElSolutions);
  const [anchorElSectors, setAnchorElSectors] = React.useState(null);
  const openSectors = Boolean(anchorElSectors);
  const [anchorElResources, setAnchorElResources] = React.useState(null);
  const openResources = Boolean(anchorElResources);
  const [selected, setSelected] = React.useState(null);

  const handleClickSectors = (event) => {
    setAnchorElSectors(event.currentTarget);
  };
  const handleCloseSectors = () => {
    setAnchorElSectors(null);
    setSelected(null);
  };
  const handleHoverSectors = (event) => {
    setSelected("sectors");
    setAnchorElSectors(event.currentTarget);
    handleCloseResources();
    handleCloseSolutions();
  };

  const handleClickResources = (event) => {
    setAnchorElResources(event.currentTarget);
  };
  const handleCloseResources = () => {
    setAnchorElResources(null);
    setSelected(null);
  };
  const handleHoverResources = (event) => {
    setAnchorElResources(event.currentTarget);
    setSelected("resources");
    handleCloseSectors();
    handleCloseSolutions();
  };

  const handleClickSolutions = (event) => {
    setAnchorElSolutions(event.currentTarget);
  };
  const handleCloseSolutions = () => {
    setAnchorElSolutions(null);
    setSelected(null);
  };
  const handleHoverSolutions = (event) => {
    setAnchorElSolutions(event.currentTarget);
    setSelected("solutions");
    handleCloseResources();
    handleCloseSectors();
  };

  const iconStyle = {
    transform: "rotate(90deg)",
    ml: 2,
    fontSize: 12,
    mt: "auto",
    mb: "auto",
  };
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      sx={style.container}
      // PaperProps={{
      //   // onMouseEnter: () => {
      //   //   handleHoverSolutions();
      //   // },
      //   onMouseLeave: () => {
      //     handleCloseSolutions();
      //   },
      // }}
    >
      {!matchesMobile && (
        <>
          <Box
            onClick={() => navigate("/home")}
            component="img"
            sx={{
              width: "238px",
              marginLeft: "2vw",
              marginRight: "30px",
            }}
            src={gridmarket}
          />
          <Stack direction="row" spacing={1}>
            <Box>
              <Button
                sx={{
                  fontWeight: "bold",
                  textDecoration: "none!important",
                  textTransform: "none!important",
                  color: "black!important",
                  borderRadius: "9px!important",
                  fontSize: "15px!important",
                }}
              >
                Marketplace
              </Button>
            </Box>

            <Box
            // onMouseLeave={() => console.log("bye")}
            // onMouseEnter={() => console.log("hi")}
            >
              <Button
                // id="Solutions-button"
                // aria-controls={openSolutions ? "Solutions-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={openSolutions ? "true" : undefined}
                onClick={handleClickSolutions}
                onMouseEnter={handleHoverSolutions}
                // onMouseLeave={handleCloseSolutions}
                // onMouseEnter={() => console.log("on mouse enter ")}
                className="navButton"
                sx={{
                  backgroundColor:
                    selected === "solutions" ? "#96c8d3" : "#FFF",
                  zIndex: theme.zIndex.modal + 1,
                }}
                // sx={{
                //   textDecoration: "none",
                //   textTransform: "none",
                //   color: "black",
                //   "&:hover": { backgroundColor: "red" },
                // }}
              >
                Who We Work With <ArrowForwardIosSharpIcon sx={iconStyle} />
              </Button>
              <Menu
                // id="Solutions-menu"
                //aria-labelledby="Solutions-button"
                anchorEl={anchorElSolutions}
                open={openSolutions}
                onClose={handleCloseSolutions}
                //onMouseLeave={handleCloseSolutions}
                // onOpenChange={() => {
                //   console.log("onOpenChange called");
                // }}
                PaperProps={{
                  onMouseEnter: () => {
                    handleHoverSolutions();
                  },
                  onMouseLeave: () => {
                    handleCloseSolutions();
                  },
                }}
                // sx={{
                //   "&.root": {
                //     width: "max-content",
                //     height: "max-content",
                //   },
                // }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/property-owners/`)
                  }
                >
                  Property Owners
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/vendors/`)
                  }
                >
                  Vendors
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/partners/`)
                  }
                >
                  Partners and Investors
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/national-transformation/`)
                  }
                >
                  Government
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/utilities/`)
                  }
                >
                  Utilities
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="resources-button"
                aria-controls={openSectors ? "sectors-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSectors ? "true" : undefined}
                onClick={handleClickSectors}
                onMouseOver={handleHoverSectors}
                className="navButton"
                sx={{
                  backgroundColor: selected === "sectors" ? "#96c8d3" : "#FFF",
                  zIndex: theme.zIndex.modal + 1,
                }}
              >
                Key Industries <ArrowForwardIosSharpIcon sx={iconStyle} />
              </Button>
              <Menu
                id="sectors-menu"
                aria-labelledby="sectors-button"
                anchorEl={anchorElSectors}
                open={openSectors}
                onClose={handleCloseSectors}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  onMouseEnter: () => {
                    handleHoverSectors();
                  },
                  onMouseLeave: () => {
                    handleCloseSectors();
                  },
                }}
              >
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/universities/`)
                  }
                >
                  Universities
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/manufacturing/`)
                  }
                >
                  Manufacturing
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/facility-and-fleet/`)
                  }
                >
                  Facility and Fleet
                </MenuItem>
                {/* <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/manufacturing/`)
                  }
                >
                  Manufacturing
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/facility-and-fleet/`)
                  }
                >
                  Facility and Fleet
                </MenuItem> */}
              </Menu>
            </Box>
            <Box>
              <Button
                id="resources-button"
                aria-controls={openResources ? "resources-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openResources ? "true" : undefined}
                onClick={handleClickResources}
                onMouseOver={handleHoverResources}
                className="navButton"
                sx={{
                  backgroundColor:
                    selected === "resources" ? "#96c8d3" : "#FFF",
                  zIndex: theme.zIndex.modal + 1,
                }}
              >
                Resources <ArrowForwardIosSharpIcon sx={iconStyle} />
              </Button>
              <Menu
                id="resources-menu"
                aria-labelledby="resources-button"
                anchorEl={anchorElResources}
                open={openResources}
                onClose={handleCloseResources}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  onMouseEnter: () => {
                    handleHoverResources();
                  },
                  onMouseLeave: () => {
                    handleCloseResources();
                  },
                }}
              >
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/case-studies/`)
                  }
                >
                  Case Studies
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/?page_id=68`)
                  }
                >
                  Blog
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/press/`)
                  }
                >
                  Press
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://gridmarket.com/newhome/`)
                  }
                >
                  New Home
                </MenuItem>
                <MenuItem
                  className="navItem"
                  onClick={() =>
                    (window.location.href = `https://platform.gridmarket.com/`)
                  }
                >
                  Platform
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                className="navButton"
                onMouseEnter={() => {
                  setSelected(null);
                }}
                onMouseLeave={() => {
                  setSelected(null);
                }}
                //    onMouseEnter: () => {
                //   handleHoverResources();
                // },
                // onMouseLeave: () => {
                //   handleCloseResources();
                // },
                sx={{
                  // textDecoration: "none",
                  // textTransform: "none",
                  // color: "black",
                  mr: 12,
                }}
              >
                About
              </Button>
            </Box>
            <Box>
              <Button
                // classname="navItem about"
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  color: "black",
                  border: "1px solid #96c8d3",
                  borderRadius: "10px",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://platform.gridmarket.com/login")
                }
                //onMouseEnter={setOnMenu(true)}
                //onMouseLeave={handleLeave}
              >
                Login
              </Button>
            </Box>
            <Box></Box>
          </Stack>
        </>
      )}
      {matchesMobile && <MobileMenu />}
    </Stack>
  );
}
function MobileMenu() {
  const [hamburgerState, setHamburgerState] = React.useState(false);
  const [solutionState, setSolutionState] = React.useState(false);
  const [sectorState, setSectorState] = React.useState(false);
  const [resourceState, setResourceState] = React.useState(false);
  const navigate = useNavigate();
  const iconStyle = {
    position: "absolute",
    right: 20,
    transform: "rotate(90deg)",
    fontSize: 12,
    mt: "auto",
    mb: "auto",
  };
  return (
    <>
      <IconButton
        id="basic-button"
        onClick={() => setHamburgerState(!hamburgerState)}
        sx={{ position: "absolute", top: 27, right: 15 }}
      >
        {hamburgerState ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Box
        onClick={() => navigate("/home")}
        component="img"
        src={gridmarket}
        sx={{
          display: hamburgerState ? "none" : "realtive",
          width: "205px",
          cursor: "pointer",
        }}
      />
      <Stack
        direction="column"
        spacing={1}
        sx={{
          m: "auto",
          width: "100vw",
          textAlign: "center",
          display: hamburgerState ? "relative" : "none",
        }}
      >
        <Box
          item
          onClick={() => navigate("/home")}
          component="img"
          sx={{
            display: hamburgerState ? "absolute" : "none",
            top: 0,
            width: "205px",
            cursor: "pointer",
            margin: "auto",
            marginTop: "12px",
          }}
          src={gridmarket}
        />
        <Box item>
          <Button
            sx={{
              marginBottom: "12px",
              marginTop: "20px!important",
              width: "100%",
              margin: "auto",
              backgroundColor: "#96c8d3",
              color: "black",
              textDecoration: "none!important",
              textTransform: "none!important",
              fontSize: "16px",
            }}
          >
            Marketplace
          </Button>
        </Box>
        <Box item>
          <Button
            sx={{
              marginBottom: "12px",
              width: "100%",
              margin: "auto",
              color: "black",
              textDecoration: "none!important",
              textTransform: "none!important",
              fontSize: "16px",
              backgroundColor: solutionState ? "#96c8d3" : "white",
              borderRadius: solutionState ? "9px 9px 0px 0px" : "9px",
              "&:hover": {
                backgroundColor: "#96c8d3",
              },
            }}
            id="Solutions-button"
            onClick={() => setSolutionState(!solutionState)}
          >
            Who We Work With
            <ArrowForwardIosSharpIcon sx={iconStyle} />
          </Button>
          <Box
            sx={{
              margin: "auto",
              width: "100%",
              display: solutionState ? "relative" : "none",
              border: "2px solid #E5E5E5",
            }}
          >
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/property-owners/`)
              }
            >
              Property Owners
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/vendors/`)
              }
            >
              Vendors
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/partners/`)
              }
            >
              Partners and Investors
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/national-transformation/`)
              }
            >
              Government
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/utilities/`)
              }
            >
              Utilities
            </Typography>
          </Box>
        </Box>
        <Box item>
          <Button
            id="sectors-button"
            sx={{
              marginBottom: "12px",
              width: "100%",
              margin: "auto",
              color: "black",
              textDecoration: "none!important",
              textTransform: "none!important",
              backgroundColor: sectorState ? "#96c8d3" : "white",
              fontSize: "16px",
              borderRadius: sectorState ? "9px 9px 0px 0px" : "9px",
              "&:hover": {
                backgroundColor: "#96c8d3",
              },
            }}
            onClick={() => setSectorState(!sectorState)}
          >
            Key Industries <ArrowForwardIosSharpIcon sx={iconStyle} />
          </Button>
          <Box
            sx={{
              width: "100%",
              m: "auto",
              display: sectorState ? "relative" : "none",
              border: "2px solid #E5E5E5",
            }}
          >
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/universities/`)
              }
            >
              Universities
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/manufacturing/`)
              }
            >
              Manufacturing
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/facility-and-fleet/`)
              }
            >
              Facility and Fleet
            </Typography>
          </Box>
        </Box>
        <Box item>
          <Button
            id="resources-button"
            sx={{
              width: "100%",
              margin: "auto",
              color: "black",
              textDecoration: "none!important",
              textTransform: "none!important",
              backgroundColor: resourceState ? "#96c8d3" : "white",
              fontSize: "16px",
              borderRadius: resourceState ? "9px 9px 0px 0px" : "9px",
              "&:hover": {
                backgroundColor: "#96c8d3",
              },
            }}
            onClick={() => setResourceState(!resourceState)}
          >
            Resources  <ArrowForwardIosSharpIcon sx={iconStyle} />
          </Button>
          <Box
            sx={{
              width: "100%",
              m: "auto",
              border: "2px solid #E5E5E5",
              borderRadius: "1px",
              display: resourceState ? "relative" : "none",
            }}
          >
            <Typography
              className="navItem"
              sx={{ backgroundColor: "#FAFAFA!important" }}
              onClick={() =>
                (window.location.href = `https://gridmarket.com/case-studies/`)
              }
            >
              Case Studies
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/?page_id=68`)
              }
            >
              Blog
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/press/`)
              }
            >
              Press
            </Typography>

            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://gridmarket.com/newhome/`)
              }
            >
              New Home
            </Typography>
            <Typography
              className="navItem"
              onClick={() =>
                (window.location.href = `https://platform.gridmarket.com/`)
              }
            >
              Platform
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            sx={{
              marginBottom: "12px",
              width: "100%",
              margin: "auto",
              color: "black",
              textDecoration: "none!important",
              textTransform: "none!important",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#96c8d3",
              },
            }}
          >
            About
          </Button>
        </Box>
        <Box>
          <Button
            sx={{
              textDecoration: "none",
              textTransform: "none",
              color: "black",
              border: "1px solid #96c8d3",
              borderRadius: "10px",
              width: "100%",
              fontSize: "16px",
              ":hover": {
                bgcolor: "#628d9b",
                color: "white",
              },
            }}
            onClick={() =>
              (window.location.href = "https://platform.gridmarket.com/login")
            }
          >
            Login
          </Button>
        </Box>
      </Stack>
    </>
  );
}
export default React.memo(NavComponent);
