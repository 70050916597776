import axios from 'axios';
import qs from 'qs';
import conf from '../conf';

const axiosInstance = axios.create({
  baseURL: conf.E2E_API_URL,
  timeout: 30000,
  withCredentials: true,
  paramsSerializer: params => {
    return qs.stringify(params, { skipNulls: true });
  }
});

export default axiosInstance;
