export default {
  building: 'Building',
  closest_power_plant: 'Nearest Power Plant',
  der: 'Der on-site',
  electricity_forecast: 'Realtime Utility and Weather',
  energy: 'Energy and environment',
  financials: 'Property Financials',
  incentives: 'Energy Incentives',
  energy_use: 'Energy Use',
  iso_territory: 'Independent System Operator',
  load_curve: 'Predicted Load Profile',
  natural_gas_service: 'Natural Gas Service Territory',
  ev: 'Electric Vehicles',
  owner: 'Ownership',
  power_line: 'Nearest Powerline',
  predictions: 'Solar Photovoltaic Production',
  temperature: 'Temperature',
  utility_territory: 'Utility Territory',
  wind_speed: 'Wind Speed',
  contacts: 'Contacts',
  tariff: 'Tariff',
  places: 'Places',
  descriptions: {
    storage_estimate: `A site-specific recommendation on the size of a Battery Storage system to be installed at this property`,
    storage_recommendation: `Suitability of Battery Storage technologies to this property type`,
    chp_estimate: `A site-specific recommendation on the size of a CHP system to be installed at this property`,
    chp_recommendation: `Suitability of CHP technologies to this property type`,
    fuel_cell_recommendation: `A site-specific recommendation on the size of a Fuel Cell system to be installed at this property`,
    fuel_cell_estimate: `Suitability of Fuel Cell technologies to this property type`,
    solar_recommendation: `A site-specific recommendation on the size of a Solar system to be installed at this property`,
    solar_estimate: `Suitability of Solar technologies to this type`,
    'lot area': `Total square footage value of parcel of land`,
    'total area': `Total square footage of building(s) on parcel`,
    'year built': `Date of intial building completion`,
    'office area': `area Sub-type of commercial area. Building usage breakdown for office area, allowing for a better understanding of building use`,
    'number of floors': `Maximum number of floors of building(s) on property`,
    'commercial area': `Building usage breakdown for commercial area, allowing for a better understanding of building use`,
    'floor area ratio': `Gross floor area of building(s) (total area) divided by lot area`,
    'number of buildings': `Number of buildings included on the property`,
    'factory area': `Sub-type of commercial area. Building usage breakdown for factory area, allowing for a better understanding of building use`,
    'retail area': `Sub-type of commercial area. Building usage breakdown for retail area, allowing for a better understanding of building use`,
    'storage area': `Able to search through any of the NYC data sets using this number`,
    'residential area': `Sub-type of commercial area. Building usage breakdown for storage area, allowing for a better understanding of building use`,
    'land use': `Land Use of the property, derived from building class`,
    'garage area': `Sub-type of commercial area. Building usage breakdown for garage area, allowing for a better understanding of building use`,
    landmark: `Name of the landmark designation on the building (if applicable)`,
    'other area': `Sub-type of commercial area. Building usage breakdown for other area, allowing for a better understanding of building use`,
    'residential units': `Number of residential units contained on the property (does not include hotel units)`,
    'market value': `DER project development can increase current market value of property`,
    'taxable value': `Actual or Transitional Assessed Value (whichever is less) minus any exemptions, used to calculate annual tax bill`,
    'tax rate': `The rate used to determine the tax owed, each tax class has it's own rate `,
    'property tax':
      'Can provide useful insight. Often when property tax is $0, the site is subsidized ',
    revenue: `Total revenue of property`,
    lender: `Name of instutution loaning the amount listed`,
    opex: `Building Operating Expense`,
    loan: `Current loan amount`,
    'net operating income': `Revenue minus OPEX`,
    'loan year': `Year of most recent loan`,
    'energy use intensity': `Total energy consumed in one year divided by total building area`,
    'electric utility': `Name of Electric Utility`,
    'energy star rating': `Screening tool that helps one assess how a building is performing. A score of 50 is the median`,
    gas_utility: `Name of Gas Utility`,
    'total ghg emissions': `Total GHG emissions from the technologies and systems on the property`,
    bouler_model: `Model of boiler currently installed on the property`,
    'total kwh': `Estimate of total kWh used on the lot in a year`,
    boiler_capacity: `Heating capacity of current boiler`,
    'year peak': `Yearly peak electric demand of building(s)`,
    'boiler installation date': `Istallation year of boiler, which can suggest a boiler is out of date and in need of an upgrade`,
    'compliance(ll87)': `Compliance years in the recent past means they energy audit information is up-to-date. A compliance year scheduled in the near future means their energy info will be updated soon`,
    'boiler oil consumption': `Estimate of yearly oil consumption by installed boiler`,
    'utility network demand peak': `When electricity demand is highest for a given network`,
    'is dual fuel': `Boiler able to accept both oil and natural gas (Y/N)`,
    owner: `Listed owner of building`,
    type: `Owner type`,
    'natural gas utility': 'Name of Gas Utility',
    'e-mail': `Email address listed for contact`,
    contact: `If an organization, contact at the organization for reaching out to initiate or continue conversations`,
    'relationship to owner': 'Relationship of Contact to owner',
    phone: 'Phone number of contact'
  }
};
